import React, { useState, useEffect, useRef } from 'react';
import sorabh from '../../assests/sD.webp';
import Rohan from '../../assests/Rohan gupta.jpg';
import '../../hamcss/mission.css';
import Rahul from '../../assests/Rahulcrop.jpg';
import anil from '../../assests/anilKumarBansal.webp';
import AshaAnil from '../../assests/Asha-Anil.webp';
import uprndra from '../../assests/HS-Upendra.webp';
import dukhbandu from '../../assests/Dukhabandhu.webp';
import rahul from '../../assests/rahul-gupta.webp';
import rohan from '../../assests/Rohan gupta.jpg';
import { Swiper, SwiperSlide } from 'swiper/react';
import { IoClose } from "react-icons/io5";
import m3 from '../../assests/m3.jpg';
import m1 from '../../assests/m1.webp';
import m2 from '../../assests/m2.webp';
import faith from '../../assests/faith.webp';




// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const Misson_Visson = () => {


    const [activeTab, setActiveTab] = useState('boardOfDirector');

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };



    const [isOpen, setIsOpen] = useState(false);

    const togglePopup = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (isOpen && event.target.closest('.popup') === null) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);


    return (

        <>

            <section  >

                <div className="main-section-box-container">

                    <div className='inside-mi-vi-box' >
                        <div className='main-mission-text'>
                            <img src={m1} alt="Our Mission" className="image-box-mi" />
                            <div>
                                <h2 style={{ wordSpacing: "2px", letterSpacing: '0.2px' }}>Our Mission</h2>
                                <p style={{ wordSpacing: "2px", letterSpacing: '0.2px' }} className='p-text-mission-v'>To deliver innovative tailored products and services to cater to the growing needs of transforming India.</p>
                            </div>
                        </div>

                        <div className='main-mission-text'>
                            <img src={m2} alt="Our Vision" className="image-box-mi" />
                            <div>
                                <h2 style={{ wordSpacing: "2px", letterSpacing: '0.2px' }}>Our Vision</h2>
                                <p style={{ wordSpacing: "2px", letterSpacing: '0.2px' }} className='p-text-mission-v'>To be partner of first choice for Supply Chain Financing Solutions for Indian Conglomerates.</p>
                            </div>
                        </div>
                    </div>

                    <div className='main-mission-text-2' style={{ backgroundImage: `url(${faith})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
                        <div className="inside-misi-val">
                            <div style={{ textAlign: 'center' }}>
                                <h2 style={{ wordSpacing: "2px", letterSpacing: '0.2px' }}>Our Values</h2>
                            </div>
                            <p style={{ wordSpacing: "2px", letterSpacing: '0.2px' }} className="p-text-mission-v">Our culture manifests through core values of Faith- “ASTHA”</p>
                            <p style={{ wordSpacing: "2px", letterSpacing: '0.2px' }} className='p-text-mission-v'>To be partner of first choice for Supply Chain Financing Solutions for Indian Conglomerates.</p>
                            <ul>
                                <li style={{ wordSpacing: "2px", letterSpacing: '0.2px' }} className="p-text-mission-v"><b>Arjava </b> means sincerity, straightness and non- hypocrisy;</li>
                                <li style={{ wordSpacing: "2px", letterSpacing: '0.2px' }} className="p-text-mission-v"><b>Satya </b> being honest at all times & under all circumstances;</li>
                                <li style={{ wordSpacing: "2px", letterSpacing: '0.2px' }} className="p-text-mission-v"><b>Tapas </b>  spectrum of practices ranging from asceticism, inner cleansing to self-discipline;</li>
                                <li style={{ wordSpacing: "2px", letterSpacing: '0.2px' }} className="p-text-mission-v"><b>Hridaya </b> that which receives, that which gives, and that which moves; </li>
                                <li style={{ wordSpacing: "2px", letterSpacing: '0.2px' }} className="p-text-mission-v"><b>Asteya</b> means non-stealing by nature;</li>
                            </ul>
                        </div>
                    </div>


                </div>


            </section>

        </>


    )
}

export default Misson_Visson