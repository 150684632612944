import React from 'react'
import careerImage from '../../assests/about/career/banner-career.webp';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
const CareerInAbout = () => {


    useEffect(() => {
        AOS.init();

    })

    const handleLinkClick = () => {
        // Scroll to the top of the page
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };


    return (


        <section
            style={{
                backgroundImage: `url(${careerImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                width: '100%',
                height: '422.4px',

            }}
        >

            <div

                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    alignContent: 'center',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.6)'
                }}
                data-aos="fade-down" data-aos-duration='1000' >
                <div >
                    <div className="careers-right">
                        <h2>Careers</h2>
                        <p>Simply by contacting us we will listen to you and help you in every way out</p>
                        <div className="banner-btn">
                            <Link onClick={handleLinkClick} to={'/career'} className="default-btn get-btn">
                                Apply Now
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default CareerInAbout