import React from 'react'
import About from './component/About';
import Home from './component/Home';
// import Careers from './component/Careers';
import Solution from './component/Solution';
import Contact from './component/Contact';
import { Route, Routes, } from 'react-router-dom';
import KnowButton from './component/KnowButton';
import Dealer from './component/solution/Dealer';
import Retailer from './component/solution/Retailer';
import Vender from './component/solution/Vender';
import Transporter from './component/solution/Transporter';
import Solution_cards from './component/solution/Solution_cards';
import Invester from './component/Invester';
// import Orbit from './component/Orbit';
import Team from './component/aboutComponent/Team';
import AnnualReport from './component/investorComponents/AnnualReport';
import Notices from './component/investorComponents/Notices';
import FinancialResult from './component/investorComponents/FinancialResult';
import Policies from './component/investorComponents/Policies';
import AnnualReturn from './component/investorComponents/AnnualReturn';
import RelatedPartyTransaction from './component/investorComponents/RelatedPartyTransaction';
import Statement_of_deviation from './component/investorComponents/Statement_of_deviation';
import Disclosures_Regulation30 from './component/investorComponents/Disclosures_Regulation30';
import Shereholding from './component/investorComponents/Shereholding';
import NewspaperPublishing from './component/investorComponents/NewspaperPublishing';
import AuditedSub from './component/investorComponents/AuditedSub';
import SecretarialComplianceReport from './component/investorComponents/SecretarialComplianceReport';
import DividendDistributionPolicy from './component/investorComponents/DividendDistributionPolicy';
import CSR from './component/CSR';
import Blog from './component/Blog';
import B1 from './component/blogs/B1';
import DeetailsOfBusiness from './component/investorComponents/DeetailsOfBusiness';
import DetailsFamilairization from './component/investorComponents/DetailsFamilairization';
import CommunicationToShareholders from './component/investorComponents/CommunicationToShareholders';
import ScheduleOfAnalyst from './component/investorComponents/ScheduleOfAnalyst';



const App = () => {


  return (

    <>


      <Routes >
        <Route path='/' element={<Home />} />
        <Route path='/aboutUs' element={<About />} />
        {/* <Route path='/career' element={<Careers />} /> */}
        <Route path='/csr-initiative' element={<CSR />} />
        <Route path='/solution' element={<Solution />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/button' element={<KnowButton />} />
        <Route path='/dealer' element={<Dealer />} />
        <Route path='/retailer' element={<Retailer />} />
        <Route path='/vender' element={<Vender />} />
        <Route path='/transport_logistics' element={<Transporter />} />
        {/* <Route path='/soltion' element={<Solution_cards />} /> */}
        <Route path='/investor' element={<Invester />} />
        <Route path='/team' element={<Team />} />
        {/* <Route path='/orbit' element={<Orbit />} /> */}


        {/* investor Relations  */}

        <Route path='/annual-report' element={<AnnualReport />} />
        <Route path='/notices' element={<Notices />} />
        <Route path='/financial-result' element={<FinancialResult />} />
        <Route path='/policy' element={<Policies />} />
        <Route path='/annual-return' element={<AnnualReturn />} />
        <Route path='/related-party-transaction' element={<RelatedPartyTransaction />} />
        <Route path='/communication-to-shareholders' element={<CommunicationToShareholders />} />
        <Route path='/statement' element={<Statement_of_deviation />} />
        <Route path='/disclosures' element={<Disclosures_Regulation30 />} />
        <Route path='/shareholding' element={<Shereholding />} />
        <Route path='/newspaper-publishing' element={<NewspaperPublishing />} />
        <Route path='/audited-financial-statements-of-subsidiaries' element={<AuditedSub />} />
        <Route path='/secretarial' element={<SecretarialComplianceReport />} />
        <Route path='/dividend-distribution-policy' element={<DividendDistributionPolicy />} />
        <Route path='/blogs' element={<Blog />} />
        <Route path='/blogs-1' element={<B1 />} />
        <Route path='/details-business' element={<DeetailsOfBusiness />} />
        <Route path='/details-familairization' element={<DetailsFamilairization />} />
        <Route path='/schedule-analyst' element={<ScheduleOfAnalyst />} />

      </Routes >

    </>

  )
}

export default App