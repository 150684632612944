import React from 'react'
import Navbar from '../Navbar';
import Footer from '../Footer';
import investerBanner from '../../assests/investor relationship/handshake.webp';
import '../../hamcss/investor.css';
import { useState } from 'react'
import { FaRegFilePdf } from "react-icons/fa";
import { Link } from 'react-router-dom';


import RPTa from '../../assests/sgPdf/RelatedPartyTransaction/FY23-24/RPT September 2023.pdf';
import RPTb from '../../assests/sgPdf/RelatedPartyTransaction/FY23-24/RPT March 2024.pdf';
import RPT24 from '../../assests/sgPdf/RelatedPartyTransaction/FY24-25/RPT_Half Yearly_September 2024.pdf';
import FY22a from '../../assests/sgPdf/AnnualReturnSection/FY2022/e6eb665cbc_MSL+MGT-7+2021.pdf';
import FY21a from '../../assests/sgPdf/AnnualReturnSection/FY2021/Annual+Return+for+Year+ended+2020.pdf';
import FY20a from '../../assests/sgPdf/AnnualReturnSection/FY2020/Annual+Return+for+Year+ended+2019.pdf';
import FY19a from '../../assests/sgPdf/AnnualReturnSection/FY2019/Annual+Return+for+Year+ended+2018.pdf'



const RelatedPartyTransaction = () => {

    const [selectedOption, setSelectedOption] = useState("option1"); // State to track selected option

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value); // Update selected option when changed
    };


    return (
        <>

            <Navbar />
            <section>
                <div className="BreadArea" style={{
                    //  for /beta server 
                    backgroundImage: `url(${investerBanner})`,
                    // backgroundImage: "url('images/Bread.png')",
                }}>
                    <div className="container">
                        <h2>Annual Return_Section 92 of Companies Act 2013</h2>
                    </div>
                </div>
            </section>


            <section className="main-an">

                <div className="main-an-box">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h2 className='h2-text'>	Related Party Transaction</h2>
                        <div>
                            <select className="select-class" onChange={handleOptionChange}>
                                <option value="option1">FY 2024-2025</option>
                                <option value="option2">FY 2023-2024</option>
                                {/* <option value="option6">FY</option>
                                <option value="option7">FY</option>
                                <option value="option8">FY</option> */}

                            </select>
                        </div>
                    </div>

                    <div style={{ marginTop: '50px' }}>
                        {/* Conditionally render PDFs based on selected option */}
                         {selectedOption === "option1" && (
                            <>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={RPT24} target='blank'>  <h6>RPT_Half Yearly_September 2024</h6> </a>
                                </div>
                                




                            </>
                        )}
                        {selectedOption === "option2" && (
                            <>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={RPTb} target='blank'>  <h6>Related Party Transaction, March 2024</h6> </a>
                                </div>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={RPTa} target='blank'>  <h6>Related Party Transaction, September 2023</h6> </a>
                                </div>
                                

                            </>

                        )}




                       


                        {selectedOption === "option3" && (
                            <>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={FY21a} target='blank'> <h6>Annual+Return+for+Year+ended+2020.pdf</h6> </a>
                                </div>

                            </>
                        )}


                        {selectedOption === "option4" && (
                            <>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={FY20a} target='blank'> <h6>Annual+Return+for+Year+ended+2019.pdf</h6> </a>
                                </div>


                            </>
                        )}


                        {selectedOption === "option5" && (
                            <>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={FY19a} target='blank'> <h6>Annual+Return+for+Year+ended+2018.pdf</h6> </a>
                                </div>

                            </>
                        )}


                        {/* {selectedOption === "option6" && (
                            <>

                            </>
                        )} */}

                    </div>
                </div>

            </section>



            <Footer />


        </>
    )
}



export default RelatedPartyTransaction