import React, { useEffect } from 'react'
import AOS from 'aos';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import { Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectCoverflow, } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Misson_Visson from "./aboutComponent/Misson_Visson";
import { Link } from 'react-router-dom';
import { useState } from 'react';
import Footer from './Footer';
import PositionText from "./aboutComponent/PositionText";
import tecSO from '../assests/solution/freepik-export-20240507091302nWwp.webp';
import tecSO2 from '../assests/solution/133849.webp';
import leaderChainImage from '../assests/home/leaders in supply chair business/1120.webp';
import Feedback from './Feedback';
import Header from './Header';
import MiniAbout from './homeComponent/MiniAbout';
import Bussiness from './homeComponent/Bussiness';
// import Priority from './aboutComponent/Priority';

// import ReactWhatsapp from "react-whatsapp";
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import BankingPartners from './homeComponent/BankingPartners';
import WhyChoose from './homeComponent/WhyChoose';
import DotRing from './dot/DotRing';
import tech from '../assests/tech.webp';


const Home = () => {

    const [slidesPerView, setSlidesPerView] = useState(4);
    const [slidesPerView2, setSlidesPerView2] = useState(1);


    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {
                setSlidesPerView(1);
            } else {
                setSlidesPerView(4);
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    useEffect(() => {
        const handleResize2 = () => {
            if (window.innerWidth >= 768) {
                setSlidesPerView2(4);
            } else {
                setSlidesPerView2(1);
            }
        };
        handleResize2();
        window.addEventListener('resize', handleResize2);
        return () => {
            window.removeEventListener('resize', handleResize2);
        };
    }, []);


    // for what we offer
    const [isLaptop, setIsLaptop] = useState(window.innerWidth >= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsLaptop(window.innerWidth >= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // for animation

    useEffect(() => {
        AOS.init();

    })




    return (

        <>

            <section style={{ display: 'flex', justifyContent: 'center' }}>
                <section className="containner">
                    <Header />
                    <DotRing />
                    {/* <Navbar /> */}
                    <div>
                        <div data-aos="fade-down" data-aos-duration='700'>
                            <MiniAbout />
                        </div>
                        <div id="mission" data-aos="zoom-in-up" data-aos-duration='800'>
                          <Misson_Visson />
                        </div>
                        <div style={{ marginTop: '70px',textAlign:'center' }} className='about-element-text about-text-box'>
                  <h2 style={{ fontWeight: '600', letterSpacing: "0.2px" }}>Finance</h2>

                  <h4 style={{ wordSpacing: '1px', letterSpacing: '0.2px' }}>Lending Business Incepted on 1st September 2022 </h4>

                  <div className="fina-class-about">
                    <div data-aos="fade-up" className='fina-class-about-inside'>
                      <h2 style={{ fontWeight: '600', color: 'white' }}>23,788 cr</h2>
                      <p style={{ fontWeight: '600', color: 'white', letterSpacing: '0.3px' }}>Total disbursement <br /> (invoice +non invoices )</p>
                    </div>

                    <div data-aos="fade-up" className='fina-class-about-inside'>
                      <h2 style={{ fontWeight: '600', color: 'white', letterSpacing: '0.3px' }}>2,19,832</h2>
                      <p style={{ fontWeight: '600', color: 'white', wordSpacing: '2px', letterSpacing: '0.3px' }}>No. of invoices <br /> discounted</p>
                    </div>

                    {/* <div data-aos="fade-up" className='fina-class-about-inside'>
                      <h2 style={{ fontWeight: '600', color: 'white' }}>+</h2>
                      <p style={{ fontWeight: '600', color: 'white', letterSpacing: '0.3px' }}>--- <br /> served</p>
                    </div> */}


                  </div>
                </div>
                        <div id="finance" data-aos="fade-out-up">
                          <PositionText />
                        </div>
                        <div className='main-tech-f' >
                      <div className='in-tech-sohome'>
                        <h2 style={{ textAlign: 'left', height: '70px', width: 'fit-content' }} className='h2-text-new-b'>Technology Solutions</h2>
                        <p style={{ textAlign: 'left' }} className='p-text-new-para' >
                          Our supply chain financing solutions can leverage our partners all the benefits of advanced technology without incurring any capital outlay or maintenance costs. We also enable digital transformation and help businesses streamline their operations for optimal efficiency.
                        </p>
                      </div>
                         <div className='in-tech-so-2home'>
                        <h2 style={{ textAlign: 'left', height: '70px', width: 'fit-content' }} className='h2-text-new-b'>Financing Solutions</h2>
                        <p style={{ textAlign: 'left' }} className='p-text-new-para' >
                          Our money lending and factoring services empowers MSMEs to unlock their cash flow potential and take control of their finances. By unlocking capital, we enable businesses to invest in growth and build a thriving enterprise that drives success.
                        </p>
                      </div>
                    </div>

                    
                        <section className="supply-business">
                            <div data-aos="fade-up" data-aos-duration='900' className="container">
                                <div className="row">
                                    <div className="col-sm-6 "  >
                                        <div data-aos="fade-left" className="supply-content-business">
                                            <h2 style={{ letterSpacing: '0.2px', wordSpacing: '1px' }}>Leaders in supply chain business</h2>
                                            <p style={{  letterSpacing: '0.2px', wordSpacing: '2px' }} className='p-text-b'>Build a robust platform with the gradual introduction of products,  with a strong B2B core. Strong cyber controls in place with Nil attacks/ leakages recorded until date; </p>
                                            <p style={{ letterSpacing: '0.2px', wordSpacing: '2px' }} className='p-text-b'> <b>Plateform : </b> One-stop solution in the form of  SG Finserve Ltd. We are catering to the financing needs of all  the parties involved in entire supply chain. </p>
                                            <Link to={'/contact'} className="default-btn know-m-btn">Contact us <i className="fa fa-angle-right "></i></Link>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 wow zoomIn" >
                                        <div className="supply-business-img">
                                            <img src={leaderChainImage} alt='sg-finserve' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <WhyChoose />
                        {/* <Bussiness /> */}
                        {/* <BankingPartners /> */}
                        <Feedback />
                        <div>
                            <FloatingWhatsApp phoneNumber="91" accountName="Customer care" />
                        </div>
                        <Footer />
                    </div >

                </section>
            </section>
        </>

    )
}

export default Home;