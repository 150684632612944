import React, { useState, useEffect, useRef } from 'react';
import AOS from 'aos';
import '../hamcss/career.css';

import chr from '../assests/lifeAtSG/Christmas/1.webp';

import { LuArrowUpRightFromCircle } from "react-icons/lu";

import p1 from '../assests/lifeAtSG/Christmas/2.webp'
import p2 from '../assests/lifeAtSG/Christmas/3.webp'
import p3 from '../assests/lifeAtSG/Christmas/4.jpg'
import p4 from '../assests/lifeAtSG/Christmas/5.webp'
import p5 from '../assests/lifeAtSG/Christmas/6.jpg'
import d1 from '../assests/lifeAtSG/diwali/IMG_6140.jpg'
import d2 from '../assests/lifeAtSG/diwali/IMG_9095.webp'
import d3 from '../assests/lifeAtSG/diwali/IMG_9096.webp'
import d4 from '../assests/lifeAtSG/diwali/IMG_9091.webp'
import d5 from '../assests/lifeAtSG/diwali/IMG_9093.webp'
import h1 from '../assests/lifeAtSG/holi/IMG_1584.webp'
import h2 from '../assests/lifeAtSG/holi/IMG_1589.webp'
import h3 from '../assests/lifeAtSG/holi/IMG_1541.webp'
import h4 from '../assests/lifeAtSG/holi/IMG_1580.webp'
import h5 from '../assests/lifeAtSG/holi/IMG_1586.webp'


import m1 from '../assests/lifeAtSG/meetings/1 (2) (1).webp'
import m2 from '../assests/lifeAtSG/meetings/2 (2) (1).webp'
import m3 from '../assests/lifeAtSG/meetings/3 (2) (1).webp'
import m4 from '../assests/lifeAtSG/meetings/4 (2) (1).webp'

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

import { EffectCoverflow, Autoplay, Pagination, } from 'swiper/modules';
import NavButton from './NavButton';
import NavButtons from './NavButtons';










const Careers = () => {




    const [visible, setIsvisible] = useState(false)
    const [visible1, setIsvisible1] = useState(false)
    const [visible2, setIsvisible2] = useState(false)
    const [visible3, setIsvisible3] = useState(false)
    const [visible4, setIsvisible4] = useState(false)

    const boxRef = useRef(null);

    const handleOpen = () => {
        setIsvisible(!visible)
    }

    const handleOpen1 = () => {
        setIsvisible1(!visible1)
    }
    const handleOpen2 = () => {
        setIsvisible2(!visible2)
    }

    const handleOpen3 = () => {
        setIsvisible3(!visible3)
    }

    const handleOpen4 = () => {
        setIsvisible4(!visible4)
    }

    const handleClickOutside = (event) => {
        if (boxRef.current && !boxRef.current.contains(event.target)) {
            setIsvisible(false);
        }

        if (boxRef.current && !boxRef.current.contains(event.target)) {
            setIsvisible1(false);
        }

        if (boxRef.current && !boxRef.current.contains(event.target)) {
            setIsvisible2(false);
        }

        if (boxRef.current && !boxRef.current.contains(event.target)) {
            setIsvisible3(false);
        }

        if (boxRef.current && !boxRef.current.contains(event.target)) {
            setIsvisible4(false);
        }
    }

    useEffect(() => {
        // Add event listener when component mounts
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup the event listener when component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);






    return (
        <>
            <section>
                <section className='main-life-box'>
                    <h2 className="h2-text-car">Life@SG Finserve</h2>
                    <div className='main-life-box-inside'>
                        <div className='text-car-wor'  >
                            <h4 style={{ color: 'blue', fontWeight: '600' }}>WORK-LIFE BALANCE</h4>
                            <h3> Making every occasion </h3> <h4>memorable with celebrations</h4>
                        </div>
                        <div className="car-cl"   >
                            <p style={{ fontSize: '18px', wordSpacing: '2px', letterSpacing: "0.2px" }}>Life at SG Finserve is all about walking the right path. By nurturing a merit based environment that respects talent, celebrates achievement, and rewards growth, we offer fast tracked careers that are limited only by your ambition. Lively and dynamic, our culture will give you ample freedom to explore possibilities beyond your finite role.
                                Interested candidates may share their resumes at <a style={{ paddingLeft: '5px', color: '#1961ac' }} href='mailto:rai@sgfinserve.com' type='email'>renu.rai@sgfinserve.com</a></p>
                        </div>
                    </div>
                </section>

                <section className="main-life-box-section">




                    <div onClick={handleOpen} className='tab-img-div'>
                        <img src={m1} alt='sg finserve celebration' className="tab-img" />
                        <div className='tab-img-in-div'>
                            <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
                                <div className="aerrow-div">
                                    <LuArrowUpRightFromCircle size={30} />
                                </div>
                                <h2 style={{ color: 'white' }}> Meetings</h2>
                            </div>
                        </div>
                    </div>



                    <div onClick={handleOpen1} className='tab-img-div'>
                        <img src={chr} alt='sg finserve celebration' className="tab-img" />
                        <div className='tab-img-in-div'>
                            <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center', gap: '10px' }}>

                                <div className="aerrow-div">
                                    <LuArrowUpRightFromCircle size={30} />
                                </div>
                                <h2 style={{ color: 'white' }}>Christmas celebration</h2>
                            </div>
                        </div>
                    </div>

                    <div onClick={handleOpen2} className='tab-img-div'>
                        <img src={h2} alt='sg finserve celebration' className="tab-img" />
                        <div className='tab-img-in-div'>
                            <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
                                <div className="aerrow-div">
                                    <LuArrowUpRightFromCircle size={30} />
                                </div>
                                <h2 style={{ color: 'white', }}>Holi celebration</h2>
                            </div>
                        </div>
                    </div>

                    <div onClick={handleOpen3} className='tab-img-div'>
                        <img src={d2} alt='sg finserve celebration' className="tab-img" />
                        <div className='tab-img-in-div'>
                            <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center', gap: '10px' }}>

                                <div className="aerrow-div">
                                    <LuArrowUpRightFromCircle size={30} />
                                </div>
                                <h2 style={{ color: 'white' }}>Diwali celebration</h2>
                            </div>
                        </div>
                    </div>


                </section>






                <section>

                    {/* Meetings */}
                    {
                        visible && (
                            <div className="popup">
                                <div ref={boxRef} className="popup-content">
                                    <Swiper
                                        effect={'coverflow'}
                                        grabCursor={true}
                                        centeredSlides={true}
                                        // slidesPerView={3}

                                        coverflowEffect={{
                                            rotate: 50,
                                            stretch: 0,
                                            depth: 100,
                                            modifier: 1,
                                            slideShadows: true,
                                        }}

                                        // pagination={true}
                                        modules={[EffectCoverflow, Pagination, Autoplay]}
                                        className="mySwiper"
                                        breakpoints={{
                                            // When window width is <= 640px
                                            640: {
                                                slidesPerView: 1,
                                                spaceBetween: 10
                                            },
                                            // When window width is <= 768px
                                            768: {
                                                slidesPerView: 2,
                                                spaceBetween: 10
                                            },
                                            // When window width is <= 1024px
                                            1024: {
                                                slidesPerView: 3,
                                                spaceBetween: 10

                                            },
                                        }}

                                        autoplay={{
                                            delay: 2000,
                                            disableOnInteraction: false,
                                            pauseOnMouseEnter: true
                                        }}

                                    >
                                        <SwiperSlide>
                                            <div className='image-div'>
                                                <img src={m2} alt='ceelebration' />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='image-div'>
                                                <img src={m1} alt='ceelebration' />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='image-div'>
                                                <img src={m3} alt='ceelebration' />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='image-div'>
                                                <img src={m4} alt='ceelebration' />
                                            </div>
                                        </SwiperSlide>




                                    </Swiper>
                                </div>
                            </div>
                        )
                    }

                    {
                        visible1 && (
                            <div className="popup">
                                <div ref={boxRef} className="popup-content">
                                    <Swiper
                                        effect={'coverflow'}
                                        grabCursor={true}
                                        centeredSlides={true}
                                        // slidesPerView={3}
                                        coverflowEffect={{
                                            rotate: 50,
                                            stretch: 0,
                                            depth: 100,
                                            modifier: 1,
                                            slideShadows: true,
                                        }}
                                        // pagination={true}
                                        modules={[EffectCoverflow, Pagination, Autoplay]}
                                        className="mySwiper"
                                        breakpoints={{
                                            // When window width is <= 640px
                                            640: {
                                                slidesPerView: 1,
                                                spaceBetween: 10
                                            },
                                            // When window width is <= 768px
                                            768: {
                                                slidesPerView: 2,
                                                spaceBetween: 10
                                            },
                                            // When window width is <= 1024px
                                            1024: {
                                                slidesPerView: 3,
                                                spaceBetween: 10

                                            },
                                        }}

                                        autoplay={{
                                            delay: 2000,
                                            disableOnInteraction: false,
                                            pauseOnMouseEnter: true
                                        }}

                                    >
                                        <SwiperSlide>
                                            <div className='image-div'>
                                                <img src={p2} alt='ceelebration' />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='image-div'>
                                                <img src={p3} alt='ceelebration' />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='image-div'>
                                                <img src={p4} alt='ceelebration' />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='image-div'>
                                                <img src={p5} alt='ceelebration' />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='image-div'>
                                                <img src={p1} alt='ceelebration' />
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                            </div>
                        )
                    }

                    {
                        visible2 && (
                            <div className="popup">
                                <div ref={boxRef} className="popup-content">
                                    <Swiper
                                        effect={'coverflow'}
                                        grabCursor={true}
                                        centeredSlides={true}
                                        // slidesPerView={3}
                                        coverflowEffect={{
                                            rotate: 50,
                                            stretch: 0,
                                            depth: 100,
                                            modifier: 1,
                                            slideShadows: true,
                                        }}
                                        // pagination={true}
                                        modules={[EffectCoverflow, Pagination, Autoplay]}
                                        className="mySwiper"
                                        breakpoints={{
                                            // When window width is <= 640px
                                            640: {
                                                slidesPerView: 1,
                                                spaceBetween: 10
                                            },
                                            // When window width is <= 768px
                                            768: {
                                                slidesPerView: 2,
                                                spaceBetween: 10
                                            },
                                            // When window width is <= 1024px
                                            1024: {
                                                slidesPerView: 3,
                                                spaceBetween: 10

                                            },
                                        }}

                                        autoplay={{
                                            delay: 2000,
                                            disableOnInteraction: false,
                                            pauseOnMouseEnter: true
                                        }}

                                    >
                                        <SwiperSlide>
                                            <div className='image-div'>
                                                <img src={h2} alt='ceelebration' />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='image-div'>
                                                <img src={h3} alt='ceelebration' />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='image-div'>
                                                <img src={h4} alt='ceelebration' />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='image-div'>
                                                <img src={h5} alt='ceelebration' />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='image-div'>
                                                <img src={h1} alt='ceelebration' />
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                            </div>
                        )
                    }

                    {
                        visible3 && (
                            <div className="popup">
                                <div ref={boxRef} className="popup-content">
                                    <Swiper
                                        effect={'coverflow'}
                                        grabCursor={true}
                                        centeredSlides={true}
                                        // slidesPerView={3}
                                        coverflowEffect={{
                                            rotate: 50,
                                            stretch: 0,
                                            depth: 100,
                                            modifier: 1,
                                            slideShadows: true,
                                        }}
                                        // pagination={true}
                                        modules={[EffectCoverflow, Pagination, Autoplay]}
                                        className="mySwiper"
                                        breakpoints={{
                                            // When window width is <= 640px
                                            640: {
                                                slidesPerView: 1,
                                                spaceBetween: 10
                                            },
                                            // When window width is <= 768px
                                            768: {
                                                slidesPerView: 2,
                                                spaceBetween: 10
                                            },
                                            // When window width is <= 1024px
                                            1024: {
                                                slidesPerView: 3,
                                                spaceBetween: 10

                                            },
                                        }}

                                        autoplay={{
                                            delay: 2000,
                                            disableOnInteraction: false,
                                            pauseOnMouseEnter: true
                                        }}

                                    >
                                        <SwiperSlide>
                                            <div className='image-div'>
                                                <img src={d1} alt='ceelebration' />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='image-div'>
                                                <img src={d3} alt='ceelebration' />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='image-div'>
                                                <img src={d4} alt='ceelebration' />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='image-div'>
                                                <img src={d5} alt='ceelebration' />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='image-div'>
                                                <img src={d2} alt='ceelebration' />
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                            </div>
                        )
                    }


                    {
                        visible4 && (
                            <div className="popup">
                                <div ref={boxRef} className="popup-content">
                                    <Swiper
                                        effect={'coverflow'}
                                        grabCursor={true}
                                        centeredSlides={true}
                                        // slidesPerView={3}
                                        coverflowEffect={{
                                            rotate: 50,
                                            stretch: 0,
                                            depth: 100,
                                            modifier: 1,
                                            slideShadows: true,
                                        }}
                                        // pagination={true}
                                        modules={[EffectCoverflow, Pagination, Autoplay]}
                                        className="mySwiper"
                                        breakpoints={{
                                            // When window width is <= 640px
                                            640: {
                                                slidesPerView: 1,
                                                spaceBetween: 10
                                            },
                                            // When window width is <= 768px
                                            768: {
                                                slidesPerView: 2,
                                                spaceBetween: 10
                                            },
                                            // When window width is <= 1024px
                                            1024: {
                                                slidesPerView: 3,
                                                spaceBetween: 10

                                            },
                                        }}

                                        autoplay={{
                                            delay: 2000,
                                            disableOnInteraction: false,
                                            pauseOnMouseEnter: true
                                        }}

                                    >
                                        <SwiperSlide>
                                            <div className='image-div'>
                                                <img src={h2} alt='ceelebration' />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='image-div'>
                                                <img src={h3} alt='ceelebration' />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='image-div'>
                                                <img src={h4} alt='ceelebration' />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='image-div'>
                                                <img src={h5} alt='ceelebration' />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='image-div'>
                                                <img src={h1} alt='ceelebration' />
                                            </div>
                                        </SwiperSlide>


                                    </Swiper>
                                </div>
                            </div>
                        )
                    }




                </section>





            </section>
        </>
    );
}

export default Careers;
