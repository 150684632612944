import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import '../hamcss/header.css';
import { Link } from 'react-router-dom';
import Navbar from '../component/Navbar';
import b1 from '../assests/home/top_banner/dealer finance (1).webp';
import ab1 from '../assests/home/top_banner/100.webp';
import b2 from '../assests/home/top_banner/logistic_finance.webp';
import b3 from '../assests/home/top_banner/vendor_finance.webp';
import b4 from '../assests/home/top_banner/retailer_finance.webp';
import trans from '../assests/home/top_banner/transposrt.jpg';
import trans2 from '../assests/home/top_banner/pexels-chanaka-906494.jpg';
import { useEffect, useState } from 'react'

import { Autoplay, EffectFade, Keyboard, Pagination, Navigation } from 'swiper/modules';
import NavButton from './NavButton';
import { gsap } from 'gsap';



const Header = () => {



    useEffect(() => {


        // Second animation
        gsap.from('.slide-title', {
            opacity: 0,
            duration: 0.6,
            delay: 0.6,
            x: -30,
        });
    }, []);

    const banners = [


        {

            image: ab1,
            title: "Dealer Financing",
            description: "Empowering Corporates to Support Their Vendors Suppliers for More Efficient Supply Chain",
            link: "/dealer",
            buttonText: "Know More"
        },



        {
            image: b3,
            title: "Vendor Financing",
            description: "Empowering Corporates to Support Their Vendors/Suppliers for More Efficient Supply Chain",
            link: "/transport_logistics",
            buttonText: "Know More"
        },


        {

            image: trans2,
            title: "Logistics/ Transporter Finance",
            description: "Empowering Corporates to Support Their Vendors/Suppliers for More Efficient Supply Chain",
            link: "/transport_logistics",
            buttonText: "Know More"
        },

        {
            image: b4,
            title: "Retailer Financing",
            description: "Empowering Corporates to Support Their Vendors/Suppliers for More Efficient Supply Chain",
            link: "/transport_logistics",
            buttonText: "Know More"

        },



    ];








    return (
        <>
            <section >
                <section className='main-header-box'>
                    <Navbar />
                    <Swiper

                        slidesPerView={1}
                        modules={[Autoplay, EffectFade, Keyboard, Pagination, Navigation]}
                        autoplay={{ delay: 3000 }}
                        className='swiper-container '
                    >
                        {banners.map((banner, index) => (
                            <SwiperSlide key={index}>
                                <div className='image-container  ' style={{ position: 'relative' }}>
                                    <img src={banner.image} alt="banner" className='image-container' />
                                    <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}></div>
                                    <h2 className='h2-text-header  slide-title' style={{ transform: 'translate(-50%, -50%)', color: 'white', }}>{banner.title}</h2>
                                </div>
                            </SwiperSlide>
                        ))}

                        <div className='nav-buttons-cl' >
                            <NavButton />
                        </div>

                    </Swiper>

                </section >
            </section >
        </>
    );
};

export default Header;
