import React from 'react'
import Navbar from '../Navbar';
import Solution_cards from '../solution/Solution_cards';
import Footer from '../Footer';
import '../../hamcss/dealer.css';
import { useEffect } from 'react';
import AOS from 'aos';
import vendorImage from '../../assests/home/top_banner/vendor_finance.webp';

import { FloatingWhatsApp } from 'react-floating-whatsapp';


const Vender = () => {

    useEffect(() => {
        AOS.init();

    })

    return (
        <>
            <div className='main_dealer_container' >
                <Navbar />
                {/* <!--start banner slider--> */}
                <section>
                    <div className="BreadArea" style={{
                        // for /beta server
                        backgroundImage: `url(${vendorImage})`,
                        // backgroundImage: "url('images/Bread.png')",
                    }}>
                        <div data-aos="fade-down" data-aos-duration='1000' className="container">
                            <h2 style={{ wordSpacing: '2px', letterSpacing: '0.2px' }}>Vendor Supplier Finance</h2>

                        </div>
                    </div>
                </section>
                <div className='dealer_child_box' >
                    <div className='dealer_text_container'>
                        <h1 style={{ wordSpacing: '2px', letterSpacing: '0.2px', fontWeight: '600' }} className='dealer_text_heading_2' >What is  Vendor Supplier Finacing for Corporate?</h1>
                        <p className='dealer_text_para'> Empowering Corporates to Support Their Vendors/Suppliers for More Efficient Supply Chain
                            Loan against <br /> Invoice/Purchase Order/Work Order to support pre-shipment financing requirements
                        </p>

                        <p className='dealer_text_para'>Vendor financing is a financial arrangement where a company provides financing to
                            its suppliers, allowing them to access capital to support their operations. This form of
                            supply chain finance offers several benefits for both buyers (the company providing
                            financing) and suppliers.
                        </p>

                        <div>
                            <div style={{ textAlign: "left" }}>
                                <h4 style={{ textAlign: "left" }}>Here&#39;s a brief overview of retailer financing product offered by SG Finserve:</h4>
                                <ul>
                                    <li className='dealer_text_para'><b>Improved Cash Flow for Suppliers:  </b>
                                        Vendor financing provides suppliers with
                                        access to capital, helping them manage cash flow gaps, purchase inventory,
                                        invest in equipment, and fund other business activities.
                                    </li>

                                    <li className='dealer_text_para'><b> Strengthened Supplier Relationships: </b>
                                        Offering financing to suppliers can
                                        enhance relationships and build loyalty, as it demonstrates a commitment to
                                        their success and supports their growth.
                                    </li>

                                    <li className='dealer_text_para'><b> Reduced Cost of Capital:</b>
                                        Suppliers may obtain financing at more favorable
                                        rates compared to traditional lending sources, reducing their cost of capital
                                        and improving profitability.
                                    </li>

                                    <li className='dealer_text_para'><b> Enhanced Supply Chain Resilience:</b>
                                        By ensuring suppliers&#39; financial stability,
                                        vendor financing helps mitigate supply chain risks, such as disruptions due to
                                        supplier insolvency or production delays.
                                    </li>
                                    <li className='dealer_text_para'><b>Streamlined Procurement Process: </b> Vendor financing can streamline the
                                        procurement process by providing suppliers with the resources needed to fulfil
                                        orders promptly, reducing lead times and improving overall efficiency.
                                    </li>

                                    <li className='dealer_text_para'><b>Competitive Advantage: </b> Offering financing as part of the purchasing process
                                        can differentiate a company from competitors and attract high-quality
                                        suppliers seeking financial support.
                                    </li>

                                </ul>

                            </div>



                        </div>

                    </div>
                </div>
                <Solution_cards />


                <FloatingWhatsApp phoneNumber="919791028585" accountName="srini" />


                <Footer />
            </div>
        </>
    )
}

export default Vender;