import React from 'react'
import '../../hamcss/mini.css';
import { Link } from 'react-router-dom';
import { useEffect } from 'react'
import AOS from 'aos'

const MiniAbout = () => {

    useEffect(() => {
        AOS.init();

    })


    const banners = [
        {

            title: "Dealer  Financing",
            description: "Dealer Financing product offered by SG Finserve is a specialized financial solution designed to support dealerships in acquiring and managing inventory. These products cater to various industries such as metals, automotive, building materials, agriculture machinery, consumer durables, and more.",
            link: "/dealer",
            buttonText: "Know More",

        },


        {

            title: "Vendor Financing",
            description: "Vendor financing is a financial arrangement where a company provides financing to its suppliers, allowing them to access capital to support their operations. This form of supply chain finance offers several benefits for both buyers (the company providing financing) and suppliers.",
            link: "/transport_logistics",
            buttonText: "Know More"
        },


        {

            title: "Logistics/ Transporter Finance",
            description: "Logistics/ Transport Finance sits at the heart of our end-to-end Logistics offering, supporting & empowering transport companies. Uncover inefficient supply chain operations and allow SGFL to finance your freight/ transport invoices. Discount freight/ transport invoices over the counter at SGFL branches and get immediate Cash into Transporter’s account.",
            link: "/transport_logistics",
            buttonText: "Know More"
        },

        {

            title: "Retailer Financing",
            description: "Retailer financing in deep-tier supply chain finance involves the retailer offering financial support to suppliers that are not directly connected but are part of the extended supply chain network.",
            link: "/transport_logistics",
            buttonText: "Know More"
        },
    ];







    return (
        <>
            <section className='main-mini-container'>

                <div className="mini-about">
                    <div className="about-element-text">
                        <h2 style={{ letterSpacing: '0.2px', wordSpacing: '1px' }}>About SG Finserve  </h2>
                        {/* <p className='p-mini' style={{ color: '#7c7f83', paddingTop:'10px' }}>SG FINSERVE LIMITED- SGFL (formerly known as Moongipa Securities Limited), is Non- banking Finance Company that caters to Supply Chain Financing solutions for Indian Conglomerates up to the last possible tier. SGFL specializes in providing financing solutions to Dealers/ Distributors, Vendors, Retailers, Logistic providers etc. through seamless tech platform solution..</p> */}
                        <p className='p-mini' style={{ wordSpacing: '2px', letterSpacing: '0.2px' }}>

                            Established in 1994, SG Finserve Limited (SGFL) focuses on revolutionizing Supply Chain Financing solutions. SGFL is a RBI registered Non- banking Finance Company (NBFC) providing inclusive business financing solutions to channel partners- Dealers, Distributors, Retailers, Buyers, Suppliers, Transporters/ Logistics etc. of Indian Corporates by all-round use of technology.
                        </p>
                    </div>

                    <div data-aos="fade-up" style={{ marginTop: '30px' }} className="banner-btn apply-banner-btn">
                        <Link to={'/aboutUs'} className="default-btn know-m-btn">Know more <i className="fa fa-angle-right slide-know-btn"></i></Link>
                    </div>
                </div>


                <div className='inside-mini-main'>
                    {banners.slice(0, 4).map((banner, index) => (
                        <div key={index} >
                            <div >
                                <div data-aos="fade-up" className='deetails-box'>
                                    <div style={{ color: 'white', padding: '10px', letterSpacing: '0.2px', wordSpacing: '1px' }}>
                                        <h4 style={{ height: '60px', color: 'black' }} >{banner.title}</h4>
                                        <p style={{ letterSpacing: '0.2px', wordSpacing: '1px' }} className='banner-title'>{banner.description}</p>

                                        <Link style={{ color: 'oranged', fontWeight: '600', fontSize: '18px' }} to={banner.link} className="">{banner.buttonText} <i className="fa fa-angle-right slide-know-btn"></i></Link>

                                    </div>
                                </div>
                            </div>
                        </div>

                    ))}

                </div>







                {/* <Swiper
                    pagination={{
                        type: 'fraction',
                    }}
                    navigation={true}
                    modules={[Pagination, Navigation]}
                    className="mySwiper"
                >
                    <div className='inside-mini-main-mobile'>
                        <SwiperSlide>
                            {banners.slice(0, 4).map((banner, index) => (
                                <div key={index} >
                                    <div >
                                        <div className='deetails-box'>
                                            <div style={{ color: 'black', padding: '10px' }}>
                                                <h4 style={{ height: '60px' }} >{banner.title}</h4>
                                                <p className='banner-title' >{banner.description}</p>
                                                <div className="banner-btn apply-banner-btn">
                                                    <Link to={banner.link} className="default-btn know-m-btn">{banner.buttonText} <i className="fa fa-angle-right slide-know-btn"></i></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            ))}


                        </SwiperSlide>
                    </div>
                </Swiper> */}







            </section >





        </>
    )
}

export default MiniAbout