import React from 'react'
import Navbar from '../Navbar';
import Solution_cards from '../solution/Solution_cards';
import Footer from '../Footer';
import '../../hamcss/dealer.css';
import { useEffect } from 'react';
import AOS from 'aos';
import reatiler from '../../assests/home/top_banner/retailer_finance.webp';

// import ReactWhatsapp from "react-whatsapp";
import { FloatingWhatsApp } from 'react-floating-whatsapp';

const Retailer = () => {

    useEffect(() => {
        AOS.init();

    })
    return (
        <>
        
            <div className='main_dealer_container' >
                <Navbar />
                {/* <!--start banner slider--> */}
                <section>
                    <div className="BreadArea" style={{
                        backgroundImage: `url(${reatiler})`,
                    }}>
                        <div data-aos="fade-down" data-aos-duration='1000' className="container">
                            <h2>Retailer Finacing</h2>

                        </div>
                    </div>
                </section>
                <div className='dealer_child_box' >
                    <div className='dealer_text_container'>
                        <h1 style={{ fontWeight: "600" }} className='dealer_text_heading_2' >What is  Retailer Finacing?</h1>

                        <p style={{ }} className='dealer_text_para'>Retailer financing in deep-tier supply chain finance involves the retailer offering
                            financial support to suppliers that are not directly connected but are part of the
                            extended supply chain network.</p>
                        <p style={{  }} className='dealer_text_para'>The primary goal of retailer financing in deep-tier supply chain finance is to ensure
                            the stability and resilience of the entire supply chain. By providing financing to lower-
                            tier suppliers, retailers help mitigate financial risks, reduce disruptions, and maintain
                            the flow of goods and services.</p>
                        <p style={{ wordBreak: 'break-all' }} className='dealer_text_para'>Digital Platform in place, enabled to include Deep Tier Financing Solutions to Retailers of the Corporate/ Dealers/ Distributors, i.e. lowest possible tier financing.
                            Tailoring financing solution to the lowest possible tier in a seamless manner.
                        </p>



                        <div>
                            <h4 style={{ textAlign: "left" }}>Here&#39;s a brief overview of retailer financing product offered by SG Finserve:</h4>
                            <ul>
                                <li className='dealer_text_para'><b>Improved Cash Flow: </b>
                                    Retailer financing helps lower-tier suppliers access
                                    much-needed capital to fulfil orders, manage production, and invest in growth
                                    initiatives.
                                </li>

                                <li className='dealer_text_para'><b>Enhanced Relationships: </b>
                                    Offering financing demonstrates a commitment to
                                    the success of suppliers, fostering stronger and more collaborative
                                    relationships throughout the supply chain.
                                </li>
                                <li className='dealer_text_para'><b> Risk Mitigation:</b>
                                    By supporting lower-tier suppliers financially, retailers reduce
                                    the risk of disruptions caused by supplier insolvency, production delays, or
                                    quality issues.
                                </li>
                                <li className='dealer_text_para'><b>Supply Chain Resilience: </b>
                                    Deep-tier financing contributes to the overall
                                    resilience of the supply chain by ensuring that all tiers of suppliers remain
                                    financially stable and capable of meeting demand.
                                </li>



                            </ul>

                        </div>



                    </div>




                </div>
                <Solution_cards />


                <FloatingWhatsApp phoneNumber="919791028585" accountName="Customer Care" />



                <Footer />
            </div>
        </>
    )
}

export default Retailer