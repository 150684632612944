import React, { useContext } from "react";
import "./DotRing.css";
import useMousePosition from '../hooks/useMousePosition';
import { MouseContext } from "../context/mouse-context";

const DotRing = () => {
    const { cursorType, cursorChangeHandler } = useContext(MouseContext);
    const { x, y } = useMousePosition();
    return (
        <>
            <div
                style={{ left: `${x}px`, top: `${y}px` }}
                className={"ring " + cursorType}
            ></div>


        </>
    );
};

export default DotRing;
