import { useState } from 'react'
import Footer from '../component/Footer';
import Navbar from '../component/Navbar';
import '../hamcss/csr.css';

import ReactWhatsapp from "react-whatsapp";
import { FaRegFilePdf } from "react-icons/fa";
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import DotRing from '../component/dot/DotRing';
import csr_charter from '../assests/sgPdf/csr/CSR-charter.pdf';
import csr_policy from '../assests/sgPdf/csr/2. CSR_Policy.pdf';
import csr1 from '../assests/CSR/2.webp';
import csr2 from '../assests/CSR/1.webp';
import csr3 from '../assests/CSR/3.webp';
import csr4 from '../assests/CSR/4.webp';
import csr5 from '../assests/CSR/IMG_8928.webp';
import csr6 from '../assests/CSR/IMG_8896.webp';
import csr7 from '../assests/CSR/IMG_1884.webp';
import banner from '../assests/CSR/banner.webp';


const CSR = () => {

    const [visible, setVisible] = useState('visible')

    const handleVisible = () => {
        setVisible(!visible)
        setVisible1(false)
    }

    const [visible1, setVisible1] = useState('')

    const handleVisible1 = () => {
        setVisible1(!visible1)
        setVisible(false)
    }


    return (
        <>




            <DotRing />
            <Navbar />

            <section>
                <div
                    className="BreadArea"
                    style={{
                        backgroundImage: `url('${banner}')`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: 'center',
                        height: "43vh",
                    }}
                >
                    <div
                        style={{ marginTop: "30px" }}
                        className="container"
                    >
                        <h2 style={{ wordSpacing: '1px', letterSpacing: '0.2px' }}>
                            CSR Initiative
                        </h2>

                    </div>
                </div>
            </section>

            <section style={{ display: 'flex', justifyContent: 'center' }}>
                <section className="containner">

                    <div className='swith-bt'>
                        <h5 className={`swith-bt-t ${visible ? 'blue-text' : ''}`} onClick={handleVisible}>CSR Overview</h5>
                        <h5 className={`swith-bt-t ${visible1 ? 'blue-text' : ''}`} onClick={handleVisible1}>Our Gallery</h5>
                    </div>

                    {visible && (
                        <section className="main-csr-box">


                            <div className="inside-csr">
                                <h3 >Introduction</h3>

                                <p className="p-text-csr-1">
                                    Responsibility is the bedrock for sustainable growth. It encompasses protecting and nurturing the ecosystem, the people, and the policies which form the foundation for continual progress. Similarly, Corporate Social Responsibility (CSR) aims to contribute to the societal goals of a philanthropic activity, or a charitable nature by engaging in, with, or supporting professional service volunteering through pro-bono programs. 

                                </p>
                                <p className="p-text-csr-1">As per the Section 135 of The Companies Act 2013, provides that companies must mandatorily contribute a certain amount towards CSR activities. CSR isn't solely about profit distribution; it also encompasses the ethical considerations and practices involved in revenue generation within an organization.
                                </p>
                                <p className="p-text-csr-1">
                                SGFL is dedicated to giving back to the community through sustainable and innovative CSR activities. This CSR’s vision & mission is to build a stronger community through empowering & enlightening the lives of underprivileged Children. This is achieved through round-the-year initiatives focused on promoting Child Education.
                                </p>
                            </div>
                            <div className="inside-csr">
                                <h3 >Our Initiative-“VIDHYADHARA”</h3>

                                <p className="p-text-csr-1">
                                    Lal Bahadur Shastri Bal Vatika School is established with a purpose to provide affordable education to kids from economically backward sections, building a strong academic foundation by educating the students up to class eighth. The school is situated in Vasundhara, Ghaziabad, UP, (run by Bharat Puria Education Society), educating the future of India, who come from the families from weak financial status.  SGFL indulge themselves with their dream as a part of their CSR activity for the first FY 2023-24 and decided to continue with the journey of small kids. The focus of our project “VIDHYADHARA” is:-

                                </p>
                                <ul>
                                <li style={{ wordSpacing: "2px", letterSpacing: '0.2px' }} className="p-text-mission-v">Providing basic requirements school going children i.e. Textbooks, uniform, sanitisation facilities, water and other basic needs to fulfil their dreams of being educated in life and do good for the nation, self and the family. </li>
                                <li style={{ wordSpacing: "2px", letterSpacing: '0.2px' }} className="p-text-mission-v">SGFL aims to provide smiles on the face of the students, teachers and the employees who were instrumental in their efforts to cherish the CSR initiative by their company</li>
                            </ul>
                                <p className="p-text-csr-1">The joint efforts of school and SGFL in bringing joy to children's lives represent a tangible reward for the CSR endeavors. This sense of fulfillment inspires to pursue such activities more fervently, fostering greater community involvement for the benefit of society as a whole.

                                </p>
                               
                            </div>
                            <div className="inside-csr">
                                <h3 >CSR Policy</h3>

                                <p className="p-text-csr-1">
                                    SGFL strongly believes that Corporate Social Responsibility is connected with the principles of sustainable growth and hence recognizes the immense opportunity it has to bring about a positive change in the lives of the communities through its business operations and CSR activities.

                                </p>
                                
                                <p className="p-text-csr-1">As our Corporate Social Responsibility (CSR), we actively implement projects and initiatives for the betterment of society.


                                </p>
                                <div className="pdf-clas1">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={csr_policy} target='blank'><h6>CSR Policy</h6></a>
                                </div>
                               <div className="pdf-clas1">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={csr_charter} target='blank'><h6>CSR Charter</h6></a>
                                </div>
                            </div>
                            <div className="inside-csr">
                                <h3 >Key Focus Areas</h3>
                                <h4 >Education</h4>
                                <p className="p-text-csr-1">
                                   To support quality education for underprivileged children, with special emphasis on education for children from marginalized communities and to improve the standard of education by strengthening the existing infrastructure and providing basic facilities.


                                </p>
                                
                                
                               
                            </div>


                            


                        </section>
                    )}


                    {visible1 && (
                        <section className="main-csr-box">

                            <div className="key-focus">

                                <div className="key-focus-inside-2">
                                    {/* <h3>Education</h3> */}
                                    <img src={csr3} alt="sg finserve csr" />
                                </div>
                                <div className="key-focus-inside-2">
                                    {/* <h3>Education</h3> */}
                                    <img src={csr4} alt="sg finserve csr" />
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column', gap: '60px' }}>

                                    <div className="key-focus-inside-2-t">
                                        {/* <h3>Education</h3> */}
                                        <img src={csr2} alt="sg finserve csr" />
                                    </div>

                                    <div className="key-focus-inside-2-t">
                                        {/* <h3>Education</h3> */}
                                        <img src={csr1} alt="sg finserve csr" />
                                    </div>


                                </div>

                            </div>

                            <div className="key-focus">

                                <div className="key-focus-inside-2">
                                    {/* <h3>Education</h3> */}
                                    <img src={csr5} alt="sg finserve csr" />
                                </div>
                                <div className="key-focus-inside-2">
                                    {/* <h3>Education</h3> */}
                                    <img src={csr6} alt="sg finserve csr" />
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column', gap: '60px' }}>

                                    <div className="key-focus-inside-2-t">
                                        {/* <h3>Education</h3> */}
                                        <img src={csr7} alt="sg finserve csr" />
                                    </div>

                                </div>

                            </div>


                        </section>
                    )}



                    <div>
                        {/* <ReactWhatsapp number="+919791028585" message="Hello World!!!" /> */}
                        <FloatingWhatsApp phoneNumber="919791028585" accountName="Customer Care" />
                    </div>



                    <Footer />
                </section>
            </section>

        </>
    )
}

export default CSR