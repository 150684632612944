import React from 'react'
import Navbar from '../Navbar';
import Footer from '../Footer';
import investerBanner from '../../assests/investor relationship/handshake.webp';
import '../../hamcss/investor.css';
import { useState } from 'react'
import { FaRegFilePdf } from "react-icons/fa";
import { Link } from 'react-router-dom';


import FY23a from '../../assests/sgPdf/AnnualReturnSection/FY2023/Annual Report-SG Finserve_2022-23.pdf';
import FY23b from '../../assests/sgPdf/AnnualReturnSection/FY2023/Annual+Return+for+Year+ended+2022.pdf';
import FY22a from '../../assests/sgPdf/AnnualReturnSection/FY2022/e6eb665cbc_MSL+MGT-7+2021.pdf';
import FY21a from '../../assests/sgPdf/AnnualReturnSection/FY2021/Annual+Return+for+Year+ended+2020.pdf';
import FY20a from '../../assests/sgPdf/AnnualReturnSection/FY2020/Annual+Return+for+Year+ended+2019.pdf';
import FY19a from '../../assests/sgPdf/AnnualReturnSection/FY2019/Annual+Return+for+Year+ended+2018.pdf'



const AnnualReturn = () => {

    const [selectedOption, setSelectedOption] = useState("option1"); // State to track selected option

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value); // Update selected option when changed
    };


    return (
        <>

            <Navbar />
            <section>
                <div className="BreadArea" style={{
                    //  for /beta server 
                    backgroundImage: `url(${investerBanner})`,
                    // backgroundImage: "url('images/Bread.png')",
                }}>
                    <div className="container">
                        <h2>Annual Return_Section 92 of Companies Act 2013</h2>
                    </div>
                </div>
            </section>


            <section className="main-an">

                <div className="main-an-box">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h2 className='h2-text'>	Annual Return_Section 92 of Companies Act 2013</h2>
                        <div>
                            <select className="select-class" onChange={handleOptionChange}>
                                <option value="option1">FY 2022-2023</option>
                                <option value="option2">FY 2021-2022</option>
                                <option value="option3">FY 2020-2021</option>
                                <option value="option4">FY 2019-2020</option>
                                <option value="option5">FY 2018-2019</option>
                                {/* <option value="option6">FY</option>
                                <option value="option7">FY</option>
                                <option value="option8">FY</option> */}

                            </select>
                        </div>
                    </div>

                    <div style={{ marginTop: '50px' }}>
                        {/* Conditionally render PDFs based on selected option */}
                        {selectedOption === "option1" && (
                            <>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={FY23a} target='blank'>  <h6>Annual Report-SG Finserve_2022-23.pdf</h6> </a>
                                </div>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={FY23b} target='blank'>  <h6>Annual+Return+for+Year+ended+2022.pdf</h6> </a>
                                </div>

                            </>

                        )}




                        {selectedOption === "option2" && (
                            <>
                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={FY22a} target='blank'> <h6>e6eb665cbc_MSL+MGT-7+2021.pdf</h6> </a>
                                </div>




                            </>
                        )}


                        {selectedOption === "option3" && (
                            <>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={FY21a} target='blank'> <h6>Annual+Return+for+Year+ended+2020.pdf</h6> </a>
                                </div>

                            </>
                        )}


                        {selectedOption === "option4" && (
                            <>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={FY20a} target='blank'> <h6>Annual+Return+for+Year+ended+2019.pdf</h6> </a>
                                </div>


                            </>
                        )}


                        {selectedOption === "option5" && (
                            <>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={FY19a} target='blank'> <h6>Annual+Return+for+Year+ended+2018.pdf</h6> </a>
                                </div>

                            </>
                        )}


                        {/* {selectedOption === "option6" && (
                            <>

                            </>
                        )} */}

                    </div>
                </div>

            </section>



            <Footer />


        </>
    )
}



export default AnnualReturn