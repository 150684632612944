import React from 'react'

import careerBanner from '../assests/career/career.webp';
import Navbar from './Navbar';
import Footer from './Footer';

import '../hamcss/blog.css';
import DotRing from '../component/dot/DotRing';
import { Link } from 'react-router-dom';
// import au from '../assests/Team/abhishek mahajan.png';

const Blog = () => {
    return (
        <>
            <section style={{  justifyContent: 'center' }}>
                <section className="containner">
                    <DotRing />

                    <Navbar />
                    <div>
                        <div className="BreadArea" style={{
                            backgroundImage: `url(${careerBanner})`,
                        }}>
                            <div
                                className="container">
                                <h2>Blogs</h2>
                            </div>
                        </div>
                    </div>

                    {/* <section className='main-container'>


                        <div className='blogimage' >

                            <img src={careerBanner} alt='blog' className='blogimage-inside' />
                            <div style={{ padding: '10px' }}>
                                <h3>LOREN LIPSUM</h3>
                                <p className="p-text-blog">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Alias harum rerum sunt repudiandae earum animi deserunt quam enim, ipsum facere fugit, veniam, ab officiis odit eligendi modi perspiciatis dolorem eum?</p>

                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Link to={'/blogs-1'}>  <p className='btn-read'>  COUNTINUE READING  &rarr;</p></Link>
                                    <p className='p-read'>APRIL 04,2024</p>
                                </div>

                            </div>

                             <div className="blog-author">
                        <div className='blog-author-inside'>
                            <img src={au} alt='author' />
                        </div>

                        <div style={{ width: '70%' }} >
                            <p style={{ margin: '0' }} className='p-read'> By <span style={{ fontWeight: '500' }}>Abhishek Mahajan</span> <br /> <span>23-04-2024</span></p>
                            <p style={{ margin: '0' }} className='p-read'>Lorem ipsum dolor sit amet consectetur adipisicing elit dicta dolore facilis ratione!</p>

                        </div>

                    </div> 

                        </div>


                        
                      



                  










                    </section>*/}
                    <h4 style={{ textAlign: 'center' }}>Under construction</h4>
                    <Footer />
                </section>
            </section>

        </>

    )
}

export default Blog