import React from 'react'
import '../../hamcss/promoter.css'

const Priority = () => {
    return (
        <div className="main-focus-box">




            <div className='div-pri' style={{ textAlign: "left", paddingBottom: "0px" }}>
                <h2 style={{ fontWeight: '600' }} >
                    FOCUS   PRIORITY SECTOR- <br />MSMEs
                </h2>
                <p className="p-text">
                    Catering to the financial needs of retailers, dealers, distributors, and wholesalers operating in retail and wholesale businesses.
                    Our borrowers are supporting various industries including agriculture, infra- projects, residential & commercial construction, utility items, IT/ telecom products etc.
                    With help of our borrowers, so many utility items become available in tier II & III towns.
                </p>

            </div>



            <div className='div-pri-2'>

                <h2 style={{ fontWeight: '600' }}
                >
                    ROBUST SECURED  PORTFOLIO-<br /> NIL GROSS NPA
                </h2>
                <p className="p-text">
                    Focus on building Robust as well as Secured Portfolio;
                    85% of the overall book is secured via Charge on Funded Inventory and Receivables generated by sale of the same;
                    In addition, we hold “STOP SUPPLY ARRANGEMENT” from the Anchors to STOP supply in case of delay/ default with SG Finserve:
                    The Loan portfolio of the Company stands robust with a current outstanding loan book of approximately INR 1658 crores as on 30th September 2023, with NIL GROSS NPA recorded.
                </p>


            </div>











        </div>

    )
}

export default Priority