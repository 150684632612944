import React from 'react'
import Navbar from '../Navbar';
import Footer from '../Footer';
import investerBanner from '../../assests/investor relationship/handshake.webp';
import '../../hamcss/investor.css';

const DividendDistributionPolicy = () => {
    return (
        <div>

            <Navbar />
            <section>
                <div className="BreadArea" style={{
                    //  for /beta server 
                    backgroundImage: `url(${investerBanner})`,
                    // backgroundImage: "url('images/Bread.png')",
                }}>
                    <div className="container">
                        <h2>
                            Dividend Distribution Policy</h2>
                    </div>
                </div>
            </section>



            <h2 style={{ textAlign: 'center' }}>NOT APPLICABLE</h2>


            <Footer />

        </div>
    )
}

export default DividendDistributionPolicy