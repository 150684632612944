import React from 'react'
import '../hamcss/btn.css';

const KnowButton = () => {
    return (

        <>

            <div class="container_hover_btn">
                <div class="center_hover_btn">
                    <button class="btn_hover_btn">
                        <svg width="131.5px" height="43.5px" viewBox="0 0 180 60" class="border">
                            <polyline points="179,1 179,59 1,59 1,1 179,1" class="bg-line" />
                            <polyline points="179,1 179,59 1,59 1,1 179,1" class="hl-line" />
                        </svg>
                        <span>KNOW MORE<i style={{paddingLeft:'16px',  alignItems:'center',fontSize:'17px'}} className="fa fa-angle-right slide-know-btn"></i></span>
                    </button>
                </div>
            </div>

        </>

    )
}

export default KnowButton