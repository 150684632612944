import React from 'react'
import Navbar from '../Navbar';
import Footer from '../Footer';
import investerBanner from '../../assests/investor relationship/handshake.webp';
import '../../hamcss/investor.css';
import { useState } from 'react'
import { FaRegFilePdf } from "react-icons/fa";
import { Link } from 'react-router-dom';


import CTS from '../../assests/sgPdf/communicationToShareholders/FY23-24/ESOS DETAILS.pdf';
import CODR from '../../assests/sgPdf/communicationToShareholders/FY24-25/1703763993735.pdf';
import PCS from '../../assests/sgPdf/communicationToShareholders/FY24-25/PCS Certificate_05.09.2024.pdf';
import CC from '../../assests/sgPdf/communicationToShareholders/FY24-25/Compliance Certificate_SG.pdf';



const CommunicationToShareholders = () => {

    const [selectedOption, setSelectedOption] = useState("option1"); // State to track selected option

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value); // Update selected option when changed
    };


    return (
        <>

            <Navbar />
            <section>
                <div className="BreadArea" style={{
                    //  for /beta server 
                    backgroundImage: `url(${investerBanner})`,
                    // backgroundImage: "url('images/Bread.png')",
                }}>
                    <div className="container">
                        <h2>Annual Return_Section 92 of Companies Act 2013</h2>
                    </div>
                </div>
            </section>


            <section className="main-an">

                <div className="main-an-box">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h2 className='h2-text'>	Communication To Shareholders</h2>
                        <div>
                            <select className="select-class" onChange={handleOptionChange}>
                                <option value="option1">FY 2024-2025</option>
                                <option value="option2">FY 2023-2024</option>
                                

                            </select>
                        </div>
                    </div>

                    <div style={{ marginTop: '50px' }}>
                        {/* Conditionally render PDFs based on selected option */}
                         {selectedOption === "option1" && (
                            <>
                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={CODR} target='blank'>  <h6>Circular on ODR</h6> </a>
                                </div>
                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={PCS} target='blank'>  <h6>PCS Certificate_05.09.2024</h6> </a>
                                </div>
                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={CC} target='blank'>  <h6>Compliance Certificate_SG</h6> </a>
                                </div>




                            </>
                        )}
                        {selectedOption === "option2" && (
                            <>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={CTS} target='blank'>  <h6>ESOS Details</h6> </a>
                                </div>

                               

                            </>

                        )}




                       


                     
                    </div>
                </div>

            </section>



            <Footer />


        </>
    )
}



export default CommunicationToShareholders