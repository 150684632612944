import React from 'react'
import Navbar from '../Navbar';
import Footer from '../Footer';
import investerBanner from '../../assests/investor relationship/handshake.webp';
import '../../hamcss/investor.css';
import { useState } from 'react'
import { FaRegFilePdf } from "react-icons/fa";
import { Link } from 'react-router-dom';

import fy231 from '../../assests/sgPdf/NewspaperPublishing/fy23/1.pdf';
import fy232 from '../../assests/sgPdf/NewspaperPublishing/fy23/2.pdf';
import delhi from '../../assests/sgPdf/NewspaperPublishing/fy23/Delhi-May-27--2022-page-1.pdf';
import fy233 from '../../assests/sgPdf/NewspaperPublishing/fy23/FE-Delhi-July-11--2024-page-15-SG Finserve Limited.pdf';
import fy234 from '../../assests/sgPdf/NewspaperPublishing/fy23/JS-Delhi-July-11--2024-page-15-SG Finserve Limited (1).pdf';
import fy235 from '../../assests/sgPdf/NewspaperPublishing/fy23/JS_Hindi Newspaper_March 2024.pdf';
import fy236 from '../../assests/sgPdf/NewspaperPublishing/fy23/FE_English Newspaper_March 2024.pdf';
import fy237 from '../../assests/sgPdf/NewspaperPublishing/fy23/FE_English Newspaper_September 2024.pdf';
import fy238 from '../../assests/sgPdf/NewspaperPublishing/fy23/JS_Hindi Newspaper_September 2024.pdf';
import fy221 from '../../assests/sgPdf/NewspaperPublishing/fy22/4.pdf'
import fy222 from '../../assests/sgPdf/NewspaperPublishing/fy22/5.pdf'
import fy223 from '../../assests/sgPdf/NewspaperPublishing/fy22/6.pdf'
import fy224 from '../../assests/sgPdf/NewspaperPublishing/fy22/7.pdf'
import fy225 from '../../assests/sgPdf/NewspaperPublishing/fy22/8.pdf'
import fy226 from '../../assests/sgPdf/NewspaperPublishing/fy22/9.pdf'
import fy227 from '../../assests/sgPdf/NewspaperPublishing/fy22/10.pdf'

import fy211 from '../../assests/sgPdf/NewspaperPublishing/fy21/11.pdf';
import fy212 from '../../assests/sgPdf/NewspaperPublishing/fy21/12.pdf';
import fy213 from '../../assests/sgPdf/NewspaperPublishing/fy21/13.pdf';
import fy214 from '../../assests/sgPdf/NewspaperPublishing/fy21/14.pdf';
import fy215 from '../../assests/sgPdf/NewspaperPublishing/fy21/15.pdf';
import fy216 from '../../assests/sgPdf/NewspaperPublishing/fy21/16.pdf';
import fy217 from '../../assests/sgPdf/NewspaperPublishing/fy21/17.pdf';


import fy201 from '../../assests/sgPdf/NewspaperPublishing/fy20/18.pdf';
import fy202 from '../../assests/sgPdf/NewspaperPublishing/fy20/19.pdf';
import fy203 from '../../assests/sgPdf/NewspaperPublishing/fy20/20.pdf';
import fy204 from '../../assests/sgPdf/NewspaperPublishing/fy20/21.pdf';
import fy205 from '../../assests/sgPdf/NewspaperPublishing/fy20/22.pdf';
import fy206 from '../../assests/sgPdf/NewspaperPublishing/fy20/23.pdf';
import fy207 from '../../assests/sgPdf/NewspaperPublishing/fy20/24.pdf';
import fy208 from '../../assests/sgPdf/NewspaperPublishing/fy20/25.pdf';
import fy209 from '../../assests/sgPdf/NewspaperPublishing/fy20/26.pdf';

import fy191 from '../../assests/sgPdf/NewspaperPublishing/fy19/27.pdf';
import fy192 from '../../assests/sgPdf/NewspaperPublishing/fy19/28.pdf';
import fy193 from '../../assests/sgPdf/NewspaperPublishing/fy19/29.pdf';
import fy194 from '../../assests/sgPdf/NewspaperPublishing/fy19/30.pdf';
import fy195 from '../../assests/sgPdf/NewspaperPublishing/fy19/31.pdf';
import fy196 from '../../assests/sgPdf/NewspaperPublishing/fy19/32.pdf';


import fy23_24bm from '../../assests/sgPdf/NewspaperPublishing/FY 2023-24/BM/Newspaper-FR-11.05.2023.pdf';
import fy23_24pos_March from '../../assests/sgPdf/NewspaperPublishing/FY 2023-24/Postal Ballot Notice/BSE PB-Newspaper Clip 26.03.2023-Intimation.pdf';
import fy23_24pos_Feb from '../../assests/sgPdf/NewspaperPublishing/FY 2023-24/Postal Ballot Notice/Newspaper-07.02.2024.pdf';
import fy22_23Aud from '../../assests/sgPdf/NewspaperPublishing/FY 2022-23/Audited Financial Result/MSL Independent Auditor Report March 20220001.pdf'


const NewspaperPublishing = () => {

    const [selectedOption, setSelectedOption] = useState("option0"); // State to track selected option

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value); // Update selected option when changed
    };


    return (
        <>

            <Navbar />
            <section>
                <div className="BreadArea" style={{
                    //  for /beta server 
                    backgroundImage: `url(${investerBanner})`,
                    // backgroundImage: "url('images/Bread.png')",
                }}>
                    <div className="container">
                        <h2>Newspaper-publishing</h2>
                    </div>
                </div>
            </section>


            <section className="main-an">

                <div className="main-an-box">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h2 className='h2-text'>Newspaper-publishing </h2>
                        <div>
                            <select className="select-class" onChange={handleOptionChange}>

                                <option value="option0">Fin. Year: 2024</option>
                                <option value="option1">Fin. Year: 2023</option>
                                <option value="option2">Fin. Year: 2022</option>
                                <option value="option3">Fin. Year: 2021</option>
                                <option value="option4">Fin. Year: 2020</option>
                                <option value="option5">Fin. Year: 2019</option>




                            </select>
                        </div>
                    </div>

                    <div style={{ marginTop: '50px' }}>
                        {/* Conditionally render PDFs based on selected option */}


                        {selectedOption === "option0" && (
                            <>

                                {/* <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy231} target='blank'>  <h6>Board Meeting Reg 47+33  </h6> </a>
                                </div> */}

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy23_24pos_Feb} target='blank'>  <h6>Postal Ballot Notice February 2024  </h6> </a>
                                </div>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy23_24pos_March} target='blank'>  <h6>Postal Ballot Notice March 2024  </h6> </a>
                                </div>



                                

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy233} target='blank'>  <h6> FE-Delhi-July-11--2024-page-15-SG Finserve Limited</h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy234} target='blank'>  <h6>JS-Delhi-July-11--2024-page-15-SG Finserve Limited</h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy235} target='blank'>  <h6>JS_Hindi Newspaper_March 2024</h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy236} target='blank'>  <h6>FE_English Newspaper_March 2024</h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy237} target='blank'>  <h6>FE_English Newspaper_September 2024</h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy238} target='blank'>  <h6>JS_Hindi Newspaper_September 2024</h6> </a>
                                </div>





                            </>

                        )}



                        {selectedOption === "option1" && (
                            <>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy231} target='blank'>  <h6>Newspaper Publication of Corrigendum to the Postal ballot notice  </h6> </a>
                                </div>

                                {/* <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy232} target='blank'>  <h6>Newspaper Publication of Board Meeting for the Quarter ended June 30, 2022  </h6> </a>
                                </div> */}

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy22_23Aud} target='blank'>  <h6> Newspaper Publication Audited Financial Result March 31, 2022 </h6> </a>
                                </div>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={delhi} target='blank'>  <h6> Newspaper publication Audited financial results march 2022 </h6> </a>
                                </div>




                            </>

                        )}




                        {selectedOption === "option2" && (
                            <>



                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy221} target='blank'>  <h6> Newspaper Publication BM outcome for Quarter December 2021 </h6> </a>
                                </div>



                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy222} target='blank'>  <h6>Newspaper Publication BM outcome for Quarter September 2021  </h6> </a>
                                </div>



                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy223} target='blank'>  <h6> 27th AGM notice Newspaper Publication </h6> </a>
                                </div>



                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy224} target='blank'>  <h6> Newspaper Publication of Unaudited Financial result for the Quarter ended June 30, 2021 </h6> </a>
                                </div>



                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy225} target='blank'>  <h6> Newspaper Publication of Board Meeting for the Quarter ended June 30, 2021 </h6> </a>
                                </div>


                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy226} target='blank'>  <h6> Newspaper Publication of Board Meeting for the Year ended March 31, 2021 </h6> </a>
                                </div>


                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy227} target='blank'>  <h6> Newspaper Publication Audited Financial Result March 31, 2021 </h6> </a>
                                </div>




                            </>
                        )}



                        {selectedOption === "option3" && (
                            <>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy211} target='blank'>  <h6> Newspaper Publication of Unaudited Financial Result for the Quarter and nine months ended December 31, 2020 </h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy212} target='blank'>  <h6>Newspaper publication Board Meeting for Q3  </h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy213} target='blank'>  <h6> Newspaper Publication of Unaudited Financial Result for the Quarter and half year ended September 30, 2020 </h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy214} target='blank'>  <h6> Newspaper publication Board Meeting for Q2 </h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy215} target='blank'>  <h6> Newspaper publication of 26th AGM Notice 2020 </h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy216} target='blank'>  <h6> Financial Result Publication June 2020 </h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy217} target='blank'>  <h6>Newspaper Publication Audited Financial Result March 31, 2020  </h6> </a>
                                </div>


                            </>
                        )}


                        {selectedOption === "option4" && (
                            <>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy201} target='blank'>  <h6> Newspaper Publication Unaudited Financial Result for the quater ended December 31, 2019 </h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy202} target='blank'>  <h6> Newspaper publication notice of board meeting for the quater ended December 31, 2019 </h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy203} target='blank'>  <h6>Unaudited Financial Results for quater ended September 30, 2019  </h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy204} target='blank'>  <h6>Newspaper publication notice of board meeting for the quater ended September 30, 2019  </h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy205} target='blank'>  <h6> Newspaper Publicationof unaudited Financial Result June 2019 </h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy206} target='blank'>  <h6> Newspaper publication notice of board meeting </h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy207} target='blank'>  <h6>  Disclosure of Related Party Transactions Pursuant to Regulation 23(9) of the SEBI (LODR), 2015</h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy208} target='blank'>  <h6>Newspaper Publication Audited Financial Result March 31, 2019  </h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy209} target='blank'>  <h6> Notice of Board Meeting May 18, 2019_Newspaper Publication </h6> </a>
                                </div>

                            </>
                        )}

                        {selectedOption === "option5" && (
                            <>



                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy191} target='blank'>  <h6> Financial Results 31-12-2018_Newspaper </h6> </a>
                                </div>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy192} target='blank'>  <h6> Notice of Board Meeting January 31, 2019_Newspaper Publication </h6> </a>
                                </div>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy193} target='blank'>  <h6> Financial Results 30-09-2018_Newspaper </h6> </a>
                                </div>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy194} target='blank'>  <h6>Notice of Board Meeting and Closure of Trading Window  </h6> </a>
                                </div>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy195} target='blank'>  <h6>  Notice of AGM_Newspaper</h6> </a>
                                </div>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy196} target='blank'>  <h6> Notice of Board Meeting and Closure of Trading Window 29-07-2018 </h6> </a>
                                </div>




                            </>
                        )}





                    </div>
                </div>

            </section>



            <Footer />


        </>
    )
}



export default NewspaperPublishing