import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

const Footer = () => {




    const handleLinkClick = () => {
        // Scroll to the top of the page
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };


    return (
        <>

            <section className="footer-area pt-70 pb-40">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-3">
                            <div className="single-footer-widget">
                                <div className="logo">
                                    <a href="#">
                                        {/* for /beta server */}
                                        <img src="/images/footer-logo.webp" alt="image" />
                                        {/* <img src="images/footer-logo.webp" alt="image" /> */}
                                    </a>
                                </div>
                                <p>Leaders in supply chain financing.</p>
                                <ul className="social">
                                    <li>
                                        <b>Follow us :</b>
                                    </li>
                                    {/* <li>
                                        <a href="#" target="_blank">
                                            <i className="fa fa-twitter"></i>
                                        </a>
                                    </li> */}
                                    <li>
                                        <a href="https://www.instagram.com/sgfinserve/?hl=en" target="_blank">
                                            <i className="fa fa-instagram"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.facebook.com/sgfinserveltd" target="_blank">
                                            <i className="fa fa-facebook"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/company/sg-finserve/" target="_blank">
                                            <i className="fa fa-linkedin-square"></i>
                                        </a>
                                    </li>
                                </ul>
                                <div className="footer-widget__about-phone">

                                    {/* <i className="fa fa-phone"></i> */}

                                    {/* <div className="footer-widget__about-phone-content">

                                        <span>Call Anytime</span>

                                        <h3>
                                            <a href="tel:+91-9205556121">+91-9205556121</a>
                                            <a href="tel:+91-9205556122">+91-9205556122</a>
                                        </h3>
                                    </div> */}

                                    {/* <!-- /.footer-widget__about-phone-content --> */}

                                </div>
                                {/* <!-- <p className="phn">Phone: <span className="ffp">+91 120-4041400</span></p>
                                    <p className="mail">E-Mail: <span className="info">info@sgfinserve.com</span></p>
                                    <p className="ad">Address: <span className="ad-para">36, Kaushambi, Behind Wave Cinemas, Ghaziabad, Uttar Pradesh-201010</span></p> --> */}

                            </div>
                        </div>
                        <div className="col-sm-2">
                            <div className="single-footer-widget f-floating mr-30">
                                <h3>Quick Links</h3>
                                <ul className="quick-links f-list">
                                    <li>
                                        <Link onClick={handleLinkClick} to={'/'} >Home</Link>
                                    </li>
                                    <li>
                                        <Link onClick={handleLinkClick} to={'/aboutUs'} >About Us</Link>
                                    </li>
                                    <li>
                                        <Link onClick={handleLinkClick} to={'/dealer'} >Dealer Finance</Link>
                                    </li>
                                    <li>
                                        <Link onClick={handleLinkClick} to={'/investor'}>Investor Relations</Link>
                                    </li>
                                    <li>
                                        <Link onClick={handleLinkClick} to={'/career'} >Careers</Link>
                                    </li>
                                    <li>
                                        <Link onClick={handleLinkClick} to={'/contact'} >Contact Us</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>


                        <div className="col-sm-3">
                            <div className="single-footer-widget f-floating">
                                <h3>Other Resources</h3>
                                <ul className="quick-links">
                                    <li>
                                        <a href="#">Privacy & Terms</a>
                                    </li>
                                    <li>
                                        <a href="#">Policy</a>
                                    </li>
                                    <li>
                                        <a href="#">Support</a>
                                    </li>
                                    <li>
                                        <a href="#">Loan Services</a>
                                    </li>
                                    <li>
                                        <a href="#">Business Loans</a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-sm-4">

                            <div className="single-footer-widget">
                                <h3>Contact Us</h3>
                                <div className="info-contact">
                                    <i className="fa fa-phone"></i>
                                    <span>
                                        <a href="tel:+91 120-4041400">+91-120-4041400</a>
                                    </span>
                                    <span>
                                        <a href="tel:+91-9205556122">+91-9205556122</a>
                                    </span>

                                </div>
                                <div className="info-contact">
                                    <i className="fa fa-envelope"></i>
                                    <span>info@sgfinserve.com ,</span><br />
                                    <span>customerservicedesk@sgfinserve.com</span>
                                </div>
                                <div className="info-contact">
                                    <i className="fa fa-map-marker"></i>
                                    <span>36 (2nd floor), Kaushambi Near Anand Vihar Terminal Ghaziabad Uttar Pradesh 201010
                                        (India).</span><br />
                                    <span>37, Hargobind Enclave Vikas Marg New Delhi 110092 (India).</span>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="row mt-2">

                        <div className="col-lg-12 col-sm-6">
                            <div className="foote-content">
                                <p className="text-white">We strictly follow the RBI directive and have set up an effective Grievance Redressal Cell to
                                    handle and address all manner of grievances. We always endeavour to carry out responsible lending, whereby we give just the
                                    loan which we know you will find convenient to pay back, but also recover our dues ethically. We never believe in any manner
                                    of high-handed or coercive recovery methods.
                                    But in case you have any complaints we will take up the matter seriously and address the issue within 5 working days.
                                    Call us at : +91-120-4041400 Email us at : info@sgfinserve.com
                                </p>
                                <p className="text-white">We would like to bring to the notice of all using our service or intending to
                                    use our service that we are a genuine loan provider
                                    with necessary approvals from the Reserve Bank of India to disburse loan to eligible customers..</p>

                                <p className="text-white">It has come to our notice that some groups are impersonating us
                                    and trying to portray that they are part of our group and eliciting fees with the false promise of giving them loans.
                                    All are requested to be wary of such groups and their similar attempts.</p>

                                <p className="text-white">No upfront fees charged by us We would like to state that we do not charge any upfront fee against our loan.
                                    Anyone charging or claiming to charge any upfront fee is not a part of our organization</p>

                                <p className="text-white">Call us at : +91-9205556122 Email us at : info@sgfinserve.com</p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <div className="copy-right-area">
                <div className="container bg-brd">
                    <div className="copy-right-content">
                        <p>
                            &#169;SG Finserve All Rights Reserved 2023
                        </p>
                    </div>
                </div>
            </div>
            <div className="go-top">
                <i className="fa fa-angle-up"></i>
            </div>

        </>

    )
}

export default Footer