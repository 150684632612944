import React from 'react'
import Navbar from '../Navbar';
import Footer from '../Footer';
import investerBanner from '../../assests/investor relationship/handshake.webp';
import '../../hamcss/investor.css';
import { useState } from 'react'
import { FaRegFilePdf } from "react-icons/fa";





const AuditedSub = () => {



    const [selectedOption, setSelectedOption] = useState("option1"); // State to track selected option

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value); // Update selected option when changed
    };


    return (
        <>


            <Navbar />
            <section>
                <div className="BreadArea" style={{
                    //  for /beta server 
                    backgroundImage: `url(${investerBanner})`,
                    // backgroundImage: "url('images/Bread.png')",
                }}>
                    <div className="container">
                        <h2>AuditedSub</h2>
                    </div>
                </div>
            </section>


            {/* <section className="main-an">

                <div className="main-an-box">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h2 className='h2-text'>Policy</h2>
                        <div>
                            <select className="select-class" onChange={handleOptionChange}>
                                <option value="option1">Option 1</option>
                                <option value="option2">Option 2</option>
                                <option value="option3">Option 3</option>
                                <option value="option4">Option 4</option>
                                <option value="option5">Option 5</option>
                                <option value="option6">Option 6</option>
                            </select>
                        </div>
                    </div>

                    <div style={{ marginTop: '50px' }}>
                        {selectedOption === "option1" && (
                            <>
                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                </div>







                            </>

                        )}
                        {selectedOption === "option2" && (
                            <>

                            </>
                        )}
                        {selectedOption === "option3" && (
                            <>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <h6>PDF Name for Option 1</h6>
                                </div>
                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <h6>PDF Name for Option 1</h6>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <h6>PDF Name for Option 1</h6>
                                </div>
                            </>
                        )}
                        {selectedOption === "option4" && (
                            <>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <h6>PDF Name for Option 1</h6>
                                </div>
                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <h6>PDF Name for Option 1</h6>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <h6>PDF Name for Option 1</h6>
                                </div>
                            </>
                        )}
                        {selectedOption === "option5" && (
                            <>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <h6>PDF Name for Option 1</h6>
                                </div>
                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <h6>PDF Name for Option 1</h6>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <h6>PDF Name for Option 1</h6>
                                </div>
                            </>
                        )}
                        {selectedOption === "option6" && (
                            <>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <h6>PDF Name for Option 1</h6>
                                </div>
                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <h6>PDF Name for Option 1</h6>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <h6>PDF Name for Option 1</h6>
                                </div>
                            </>
                        )}
                    </div>
                </div>

            </section> */}

            <h4 style={{ textAlign: 'center' }}>Not applicable</h4>

            <Footer />


        </>
    )
}



export default AuditedSub