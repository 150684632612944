import React from 'react'
import Navbar from '../Navbar';
import Footer from '../Footer';
import investerBanner from '../../assests/investor relationship/handshake.webp';
import '../../hamcss/investor.css';
import { useState } from 'react'
import { FaRegFilePdf } from "react-icons/fa";
import fy221 from '../../assests/sgPdf/secreatial/fy22/1.pdf';
import fy201 from '../../assests/sgPdf/secreatial/fy21/2.pdf';
import fy231 from '../../assests/sgPdf/secreatial/fy23/MR-3.pdf';
import fy241 from '../../assests/sgPdf/secreatial/fy24/Secretarial Compliance Report 2024.pdf';




const SecretarialComplianceReport = () => {



    const [selectedOption, setSelectedOption] = useState("option1"); // State to track selected option

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value); // Update selected option when changed
    };


    return (
        <>


            <Navbar />
            <section>
                <div className="BreadArea" style={{
                    //  for /beta server 
                    backgroundImage: `url(${investerBanner})`,
                    // backgroundImage: "url('images/Bread.png')",
                }}>
                    <div className="container">
                        <h2>Secretarial Compliance Report</h2>
                    </div>
                </div>
            </section>


            <section className="main-an">

                <div className="main-an-box">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h2 className='h2-text'>Secretarial Compliance Report</h2>
                        <div>
                            <select className="select-class" onChange={handleOptionChange}>
                                <option value="option1">FY 2023-2024</option>
                                <option value="option2">FY 2022-2023</option>
                               {/* <option value="option3">FY 2021-2022</option>
                                <option value="option4">FY 2020-2021</option>
                                 <option value="option4">FY 2019-2020</option> */}

                            </select>
                        </div>
                    </div>

                    <div style={{ marginTop: '50px' }}>
                        {selectedOption === "option1" && (
                            <>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy241} target='blank'>   <h6>SECRETARIAL COMPLIANCE REPORT</h6> </a>
                                </div>



                            </>

                        )}



                        {selectedOption === "option2" && (
                            <>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy231} target='blank'>   <h6>SECRETARIAL AUDIT REPORT</h6> </a>
                                </div>

                            </>
                        )}

                        {selectedOption === "option3" && (
                            <>
                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy221} target='blank'>   <h6>Familarisation Program of ID 2021-22</h6> </a>
                                </div>
                            </>
                        )}
                        {selectedOption === "option4" && (
                            <>

                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy201} target="blank">    <h6>Familarisation Program of ID 2020-21</h6></a>
                                </div>

                            </>
                        )}



                    </div>
                </div>

            </section>


            <Footer />


        </>
    )
}





export default SecretarialComplianceReport