import React from 'react'
import '../../hamcss/blog.css';
const Recents = () => {
    return (
        <div className='main-blog-con-secend'>
            <h5 style={{ paddingBottom: '10px' }}>RECENTS POST</h5>
            <p className='p-read-se' >Lorem ipsum, dolor sit amet consectetur adipisicing .</p>
            <p className='p-read-se' >Lorem ipsum, dolor sit amet consectetur adipisicing .</p>
            <p className='p-read-se' >Lorem ipsum, dolor sit amet consectetur adipisicing .</p>
            <p className='p-read-se' >Lorem ipsum, dolor sit amet consectetur adipisicing .</p>
            <p className='p-read-se' >Lorem ipsum, dolor sit amet consectetur adipisicing .</p>
            <p className='p-read-se' >Lorem ipsum, dolor sit amet consectetur adipisicing .</p>
            <p className='p-read-se' >Lorem ipsum, dolor sit amet consectetur adipisicing .</p>

        </div>
    )
}

export default Recents