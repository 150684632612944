import React from 'react'
import '../hamcss/feedback.css';


import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectCoverflow, } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const Feedback = () => {
    return (

        <section className="main-head-sec-feed">

            <section className="feedback-sec">
                <h2 className='h2-text-client'>Client Speak</h2>
                <Swiper
                    spaceBetween={20}
                    slidesPerView={1}
                    modules={[Navigation, Autoplay, Scrollbar, A11y, Pagination]}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                    breakpoints={{
                        // when window width is <= 640px
                        640: {
                            slidesPerView: 4,
                        },
                    }}
                >
                    <div className="main_feedback">


                        <SwiperSlide>
                            <div className='feedback_card'>

                                <div className="client-speak-content">
                                    <h2 style={{ height: '35px' }}>CFO- Shankara Building Products Limited</h2>
                                    <p style={{  height: '120px' }}>I am happy and thankful for the prompt response I receive from you people. Your processes are very fast & smooth. A Big Thank you for all the services.</p>
                                    <h3>Alex Varghese</h3>
                                    <span>Owner</span>
                                </div>


                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className='feedback_card'>

                                <div className="client-speak-content">
                                    <h2 style={{ height: '35px' }}>Ravi Tubes and Pipes Private Limited</h2>
                                    <p style={{  height: '120px' }}>I am very happy with your quick response time. I appreciate your service and dedication
                                        towards the customer's query resolution.</p>
                                    <h3>Ravi Mittal</h3>
                                    <span>Owner</span>
                                </div>

                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className='feedback_card'>

                                <div className="client-speak-content">
                                    <h2 style={{ height: '35px' }}>H Vikas Pipe & Steel LLP</h2>
                                    <p style={{  height: '120px' }}>The suggestions and advice given by your employees are very good. They have been very cooperative during the interactions we have on call.
                                    </p>
                                    <h3>Abhishek Aggarwal</h3>
                                    <span>Owner</span>
                                </div>

                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='feedback_card'>

                                <div className="client-speak-content">
                                    <h2 style={{ height: '35px' }}>CFO- Shankara Building Products Limited</h2>
                                    <p style={{  height: '120px' }}>I am happy and thankful for the prompt response I receive from you people. Your processes are very fast & smooth. A Big Thank you for all the services.</p>
                                    <h3>Alex Varghese</h3>
                                    <span>CFO</span>
                                </div>


                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className='feedback_card'>

                                <div className="client-speak-content">
                                    <h2 style={{ height: '45px' }}>Ravi Tubes and Pipes Private Limited</h2>
                                    <p style={{  height: '120px' }}>I am very happy with your quick response time. I appreciate your service and dedication
                                        towards the customer's query resolution.</p>
                                    <h3>Ravi Mittal</h3>
                                    <span>Owner</span>
                                </div>

                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className='feedback_card'>

                                <div className="client-speak-content">
                                    <h2 style={{ height: '45px' }}>H Vikas Pipe & Steel LLP</h2>
                                    <p style={{ height: '120px' }}>The suggestions and advice given by your employees are very good. They have been very cooperative during the interactions we have on call.
                                    </p>
                                    <h3>Abhishek Aggarwal</h3>
                                    <span>Owner</span>
                                </div>

                            </div>
                        </SwiperSlide>

                    </div>
                </Swiper>


            </section >


        </section>




    )
}

export default Feedback