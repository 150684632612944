import React from 'react';
import { useSwiper } from 'swiper/react';
import { HiArrowLongLeft, HiArrowLongRight } from 'react-icons/hi2';

const NavButtons = () => {
    const swiper = useSwiper();

    const buttonStyle = {
        backgroundColor: 'transparent',
        border: '2px solid #1961ac',
        borderRadius: '100px',
        width: '45px',
        height: '45px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        margin: '0',

    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '1rem',
                alignItems: 'center',
                justifyContent: 'center',
                // marginTop: '20px',

            }}
        >
            <div>
                <button
                    style={buttonStyle}
                    onClick={() => swiper.slidePrev()}
                >
                    <HiArrowLongLeft style={{ color: '#1961ac' }} size={30} />
                </button>
            </div>
            <button
                style={buttonStyle}
                onClick={() => swiper.slideNext()}
            >
                <HiArrowLongRight style={{ color: '#1961ac' }} size={30} />
            </button>
        </div>
    );
};

export default NavButtons;
