import {useEffect} from 'react'

import '../../hamcss/Choose.css';

import { BsStopwatch } from "react-icons/bs";
import { CiCloudOn } from "react-icons/ci";
import { PiNotePencilThin } from "react-icons/pi";
import { GoShieldCheck } from "react-icons/go";
import AOS from 'aos';

const WhyChoose = () => {


    useEffect(() => {
        AOS.init();

    })


    return (
        <>

            <section className="main-choose">

                <div className='main-choose-inside'>
                    <h2 style={{ fontWeight: '700', fontSize: '40px', textAlign: 'center', letterSpacing: '0.2px', wordSpacing: '1px' }}>
                        Why  SG Finserve Limited ?
                    </h2>
                    <p style={{ fontSize: '18px', fontWeight: '500', wordBreak: 'break-all', wordSpacing: "1px", textAlign: 'center', letterSpacing: '0.2px', }}>Catering to the financial needs of retailers, dealers, distributors, and wholesalers  operating in
                        retail and wholesale businesses.</p>

                </div>



                <div className='main-choose-inside-2'>

                    <div data-aos="fade-up" data-aos-duration='400' className="div-box-choose">
                        <div style={{ backgroundColor: '#f5f6ff', color: 'blue', }} className='icon-class-c'>
                            <BsStopwatch size={25} />
                        </div>

                        <p className="p-text-c">
                            Digital KYC Process
                        </p>


                    </div>


                    <div data-aos="fade-up" data-aos-duration='900' className="div-box-choose">
                        <div style={{ padding: '15px', backgroundColor: '#fdf6f6', width: '58px', color: 'red', borderRadius: '10px' }} className='icon-class-c'>
                            <PiNotePencilThin size={25} />
                        </div>

                        <h5 className="p-text-c">
                            Quick Disbursement
                        </h5>
                        {/* <p style={{ fontSize: '18px', wordBreak: 'break-all', wordSpacing: "2px", }}>
                            Quick disbursement ensures swift and efficient fund release.
                        </p> */}

                    </div>

                    <div data-aos="fade-up" data-aos-duration='1000' className="div-box-choose">
                        <div style={{ padding: '15px', backgroundColor: '#f5f6ff', width: '58px', color: '#2192ef', borderRadius: '10px' }} className='icon-class-c'>
                            <CiCloudOn size={25} />
                        </div>

                        <p className="p-text-c">
                            Dedicated Customer Support
                        </p>
                        {/* <p style={{ fontSize: '18px', wordBreak: 'break-all', wordSpacing: "2px", }}>
                            Providing dedicated customer support to address inquiries, resolve issues, and offer guidance.

                        </p> */}

                    </div>

                    <div data-aos="fade-up" data-aos-duration='1100' className="div-box-choose">
                        <div style={{ padding: '15px', backgroundColor: '#f5fbf8', width: '58px', color: 'green', borderRadius: '10px', }} className='icon-class-c'>
                            <GoShieldCheck size={25} />
                        </div>

                        <p className="p-text-c">
                            Insights & Analyses
                        </p>
                        {/* <p style={{ fontSize: '18px', wordBreak: 'break-all', wordSpacing: "2px", }}>
                        SG Finserve Limited is a one-stop shop for all kinds of Supply Chain Finance Solutions offering
                        </p> */}

                    </div>

                    <div data-aos="fade-up" data-aos-duration='1300' className="div-box-choose">
                        <div style={{ padding: '15px', backgroundColor: '#f5fbf8', width: '58px', color: 'green', borderRadius: '10px', }}>
                            <GoShieldCheck size={25} />
                        </div>

                        <p className="p-text-c">
                            Exclusive Offers and Competitive Rates
                        </p>


                    </div>

                </div>



            </section>



        </>
    )
}

export default WhyChoose