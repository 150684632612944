import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
const ScrollToBottom = ({ children }) => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(
            {
                top: 0,
                behavior: 'smooth',
                duration: 10
            }
        );
    }, [pathname]);

    return children || null;
}

export default ScrollToBottom