import React from 'react'
import Navbar from '../Navbar';
import Footer from '../Footer';
import investerBanner from '../../assests/investor relationship/handshake.webp';
import '../../hamcss/investor.css';
import { useState } from 'react'
import { FaRegFilePdf } from "react-icons/fa";
import { Link } from 'react-router-dom';
import fy25c from '../../assests/sgPdf/Disclosures_Regulation30/fy24_25/Outcome of BM_10.07.2024.pdf';
import fy25d from '../../assests/sgPdf/Disclosures_Regulation30/fy24_25/Outcome of BM_28.05.2024.pdf';
import fy25e from '../../assests/sgPdf/Disclosures_Regulation30/fy24_25/Press Release_September 2024.pdf';
import fy25f from '../../assests/sgPdf/Disclosures_Regulation30/fy24_25/Allotment Outcome.pdf';
import fy25 from '../../assests/sgPdf/Disclosures_Regulation30/fy24_25/Q1FY25-Press Release.pdf';
import fy25a from '../../assests/sgPdf/Disclosures_Regulation30/fy24_25/31.03.2024-Press Release.c4a52721ee1e76431eda.pdf';
import fy25b from '../../assests/sgPdf/Disclosures_Regulation30/fy24_25/Ouctome of Board Meeting_05.09.2024 (1).pdf';
import fy24a from '../../assests/sgPdf/Disclosures_Regulation30/fy23_24/Postal ballot results-07.03.2024.pdf';
import fy24b from '../../assests/sgPdf/Disclosures_Regulation30/fy23_24/Trading Window Closure 31.03.2024 SGFL.pdf';
import fy23a from '../../assests/sgPdf/Disclosures_Regulation30/fy22_23/Familarisation Program of ID 2021-22.pdf';
import fy22a from '../../assests/sgPdf/Disclosures_Regulation30/fy21-22/Familarisation Program of ID 2020-21.pdf';

const Disclosures_Regulation30 = () => {

    const [selectedOption, setSelectedOption] = useState("option"); // State to track selected option

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value); // Update selected option when changed
    };


    return (
        <>

            <Navbar />
            <section>
                <div className="BreadArea" style={{
                    //  for /beta server 
                    backgroundImage: `url(${investerBanner})`,
                    // backgroundImage: "url('images/Bread.png')",
                }}>
                    <div className="container">
                        <h2>Disclosures regulation </h2>
                    </div>
                </div>
            </section>


            <section className="main-an">

                <div className="main-an-box">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h2 className='h2-text'>Disclosures regulation </h2>
                        <div>
                            <select className="select-class" onChange={handleOptionChange}>
                                <option value="option">FY 2024-2025</option>
                                <option value="option1">FY 2023-2024</option>
                                {/*<option value="option2">FY 2022-2023</option>
                                <option value="option3">FY 2021-2022</option> */}


                            </select>
                        </div>
                    </div>

                    <div style={{ marginTop: '50px' }}>
                        {/* Conditionally render PDFs based on selected option */}
                        {selectedOption === "option" && (
                            <>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy25a} target='blank'>  <h6>Press Release For March 2024</h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy25} target='blank'>  <h6>Press Release For June 2024</h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy25b} target='blank'>  <h6>Ouctome of Board Meeting_05.09.2024</h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy25c} target='blank'>  <h6>Outcome of BM_10.07.2024</h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy25d} target='blank'>  <h6>Outcome of BM_28.05.2024</h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy25e} target='blank'>  <h6>Press Release_September 2024</h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy25f} target='blank'>  <h6>Allotment Outcome</h6> </a>
                                </div>
                               

                            </>

                        )}
                        {selectedOption === "option1" && (
                            <>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy24a} target='blank'>  <h6>Postal ballot results-07.03.2024.pdf</h6> </a>
                                </div>

                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy24b} target='blank'>  <h6>Trading Window Closure 31.03.2024 SGFL.pdf</h6> </a>
                                </div>

                            </>

                        )}




                        {selectedOption === "option2" && (
                            <>




                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy23a} target='blank'> <h6>Familarisation Program of ID 2021-22.pdf</h6> </a>
                                </div>






                            </>
                        )}
                        {selectedOption === "option3" && (
                            <>

                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy22a} target='blank'> <h6>Familarisation Program of ID 2020-21.pdf</h6> </a>
                                </div>
                            </>
                        )}
                        {selectedOption === "option4" && (
                            <>

                            </>
                        )}
                        {selectedOption === "option5" && (
                            <>

                            </>
                        )}
                        {selectedOption === "option6" && (
                            <>

                            </>
                        )}
                    </div>
                </div>

            </section>



            <Footer />


        </>
    )
}




export default Disclosures_Regulation30