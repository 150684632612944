import React, { useEffect } from 'react'
import AOS from 'aos';
import { Link } from 'react-router-dom';
import Hamburger from './Hamburger';
import Navbar from './Navbar';
import Footer from './Footer';
import DotRing from '../component/dot/DotRing';

const Solution = () => {

    useEffect(() => {
        AOS.init();
    })


    return (

        <>


            <DotRing />

            <div>

                <Navbar />

                {/* <!--start banner slider--> */}
                <section>
                    <div className="BreadArea" style={{
                        // for /beta server
                        backgroundImage: "url('/images/Bread.png')",
                        // backgroundImage: "url('images/Bread.png')",
                    }}>
                        <div className="container">
                            <h2>Solutions</h2>
                            <ul>
                                <li><a href="index.html">Home</a></li>
                                <li className="active"><a href="solutions.html">Solutions</a></li>
                            </ul>

                        </div>
                    </div>
                </section>

                <section className="solutionarea" id="dealer-fin">
                    <div className="container">
                        {/* <!--  <div className="mob-offer-head">
                            <h2>Dealer/ Distributor Financing for Corporates</h2>
                        </div> --> */}
                        <div className="row border-bg">
                            <div className="col-sm-4 wow zoomIn" style={{
                                visibility: 'visible',
                                // animationName: 'zoomIn'
                            }} >
                                <div
                                    data-aos='fade-down'
                                    data-aos-duration="1000"
                                    className="left-portion">
                                    {/* for /beta server */}
                                    <img src="/images/Dealer-solution.webp" />
                                    {/* <img src="images/Dealer-solution.webp" /> */}
                                </div>
                            </div>
                            <div className="col-sm-8 wow bounceInRight">
                                <div className="right-portion">
                                    <h2 data-aos='fade-down'
                                        data-aos-duration="900" >Dealer/ Distributor Financing for Corporates</h2>
                                    <p data-aos='fade-down'
                                        data-aos-duration="1000">Empowering Corporates to Support Their Dealers/Distributors to Catalyse Sales.</p>
                                    <ul>
                                        <li
                                            data-aos='fade-down'
                                            data-aos-duration="1100"
                                        >Distributors and dealers form an important link in the supply chain network. We aim to provide
                                            need-based financial assistance to such distributors and dealers via Line of Credit </li>
                                        <li
                                            data-aos='fade-down'
                                            data-aos-duration="1200"
                                        >The funding is secured by the purchases made by the dealer or distributor from the Corporate
                                        </li>
                                        <li
                                            data-aos='fade-down'
                                            data-aos-duration="1300"
                                        >This gives dealers and distributors access to additional working capital, enabling them to
                                            drive business growth and increase the sales penetration for the Corporate </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="logisticsarea" id="Retailer">
                    <div className="container">
                        <div className="row border-bg">
                            <div className="col-sm-8 wow bounceInLeft"   >
                                <div className="left-portion-logis ret">
                                    <h2
                                        data-aos='fade-down'
                                        data-aos-duration="1000"
                                    >Retailer Finacing</h2>
                                    <p
                                        data-aos='fade-down'
                                        data-aos-duration="1100"
                                        className="platform-include">Digital Platform in place, enabled to include Deep Tier Financing
                                        Solutions to Retailers of the Corporate/ Dealers/ Distributors, i.e. lowest possible tier
                                        financing. </p>
                                    <p
                                        data-aos='fade-down'
                                        data-aos-duration="1200"
                                        className="tailoring-para">Tailoring financing solution to the lowest possible tier in a seamless
                                        manner. </p>
                                </div>
                            </div>
                            <div className="col-sm-4 wow zoomIn" style={{
                                visibility: 'visible',
                                // animationName: 'zoomIn'
                            }} >
                                <div
                                    data-aos='fade-down'
                                    data-aos-duration="1000"
                                    className="right-portion-logis">
                                    {/* for /beta server */}
                                    <img src="/images/Retailer-solution.webp" />
                                    {/* <img src="images/Retailer-solution.webp" /> */}
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                <section className="vendorarea" id="vendor">
                    <div className="container">
                        <div className="row border-bg">
                            <div className="col-sm-4 wow zoomIn" style={{
                                visibility: 'visible',
                                // animationName: 'zoomIn'
                            }} >
                                <div
                                    data-aos='fade-down'
                                    data-aos-duration="1000"
                                    className="left-portion">
                                    {/* for /beta server */}
                                    <img src="/images/Vendor-solution.webp" />
                                    {/* <img src="images/Vendor-solution.webp" /> */}
                                </div>
                            </div>
                            <div className="col-sm-8 wow bounceInRight" data-wow-duration="1.5s"
                                style={{
                                    visibility: 'visible',
                                    // animationDuration: '1.5s',
                                    // animationName: 'bounceInRight'
                                }} >
                                <div className="right-portion vendor-corporate">
                                    <h2
                                        data-aos='fade-down'
                                        data-aos-duration="900"
                                    >Vendor Supplier Finacing for Corporate</h2>
                                    <p
                                        data-aos='fade-down'
                                        data-aos-duration="1000"
                                        className="empowering-corp">Empowering Corporates to Support Their Vendors/Suppliers for More Efficient
                                        Supply Chain</p>
                                    <p
                                        data-aos='fade-down'
                                        data-aos-duration="1100"
                                        className="m-mt-10" style={{ fontWeight: 300, }}>
                                        Loan against Invoice/Purchase Order/Work Order to support
                                        pre-shipment financing requirements
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="logisticsarea" id="Logistics">
                    <div className="container">
                        <div className="row border-bg">
                            <div className="col-sm-8 wow bounceInLeft" >
                                <div className="left-portion-logis">
                                    <h2
                                        data-aos='fade-down'
                                        data-aos-duration="900"
                                    >Logistics/ Transporter Financing</h2>
                                    <p
                                        data-aos='fade-down'
                                        data-aos-duration="1000"
                                    >Get Immediate Cash Against Freight/Transport Invoices</p>
                                    <ul>
                                        <li
                                            data-aos='fade-down'
                                            data-aos-duration="1100"
                                        >Logistics/ Transport Finance sits at the heart of our end-to-end Logistics offering, supporting
                                            &amp; empowering transport companies. </li>
                                        <li
                                            data-aos='fade-down'
                                            data-aos-duration="1200"
                                        >Uncover inefficient supply chain operations and allow SGFL to finance your freight/ transport
                                            invoices. </li>
                                        <li
                                            data-aos='fade-down'
                                            data-aos-duration="1300"
                                        >Discount freight/ transport invoices over the counter at SGFL branches and get immediate Cash
                                            into Transporter’s account.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-sm-4 wow zoomIn" >
                                <div
                                    data-aos='fade-down'
                                    data-aos-duration="1000"
                                    className="right-portion-logis">
                                    {/* for /beta server */}
                                    <img src="/images/Logistics-solution.webp" />
                                    {/* <img src="images/Logistics-solution.webp" /> */}
                                </div>
                            </div>

                        </div>
                    </div>
                </section>






                <section>

                </section>









                <Footer />
            </div>

        </>


    )
}

export default Solution;