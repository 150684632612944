import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Link, useLocation } from "react-router-dom";
import AOS from "aos";
import { useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Directer from "./aboutComponent/Directer";
import Misson_Visson from "./aboutComponent/Misson_Visson";
import "../hamcss/directer.css";
import CareerInAbout from "./aboutComponent/CareerInAbout";
import PositionText from "./aboutComponent/PositionText";
import Team from "./aboutComponent/Team";
import Priority from '../component/aboutComponent/Priority';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import tech from '../assests/tech.webp';
import Fin from '../assests/fin.jpg';
import SolutionText from "./aboutComponent/SolutionText";
import DotRing from '../component/dot/DotRing';
import BankingPartners from './homeComponent/BankingPartners';
import Bussiness from './homeComponent/Bussiness';
import Careers from '../component/Careers'
import bgIllus from '../assests/icons/image (22).png';
import tc from '../assests/icons/te.webp';
import tc2 from '../assests/icons/tc2.webp';
import bg from '../assests/icons/bg.webp';
import tecSO from '../assests/solution/freepik-export-20240507091302nWwp.webp';
import tecSO2 from '../assests/solution/133849.webp';
import b1 from '../assests/home/top_banner/dealer finance (1).webp';
import ab1 from '../assests/home/top_banner/100.webp';
import b2 from '../assests/home/top_banner/logistic_finance.webp';
import b3 from '../assests/home/top_banner/vendor_finance.webp';
import b4 from '../assests/home/top_banner/retailer_finance.webp';
import trans from '../assests/home/top_banner/transposrt.jpg';
import trans2 from '../assests/home/top_banner/pexels-chanaka-906494.jpg';
import { Autoplay, EffectFade, Keyboard, Pagination, Navigation } from 'swiper/modules';
import NavButton from './NavButton';
// import { Link } from 'react-scroll'

const About = () => {
  useEffect(() => {
    AOS.init();
  });



  const location = useLocation();
  const banners = [


        {

            image: ab1,
            title: "Streamlining the Supply Chain Financing  and  optimizing the working capital cycle ensuring Financial Inclusion for Indian MSMEs by all-round use of technology",
            
            
        },



        {
            image: b3,
            title: "Streamlining the Supply Chain Financing  and  optimizing the working capital cycle ensuring Financial Inclusion for Indian MSMEs by all-round use of technology",
            
        },


        {

            image: trans2,
            title: "Streamlining the Supply Chain Financing  and  optimizing the working capital cycle ensuring Financial Inclusion for Indian MSMEs by all-round use of technology",
        },

        {
            image: b4,
            title: "Streamlining the Supply Chain Financing  and  optimizing the working capital cycle ensuring Financial Inclusion for Indian MSMEs by all-round use of technology",

        },



    ];

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const scrollTo = urlParams.get('scrollTo');

    if (scrollTo) {
      const section = document.getElementById(scrollTo);
      if (section) {
        setTimeout(() => {
          window.scrollTo({
            behavior: 'smooth',
            top: section.offsetTop,
          });
        }, 100);
      }
    }
  }, [location.search]);



  return (
    <>
    <section >
                <section className='main-header-box'>
                    <Navbar />
                    <Swiper

                        slidesPerView={1}
                        modules={[Autoplay, EffectFade, Keyboard, Pagination, Navigation]}
                        autoplay={{ delay: 3000 }}
                        className='swiper-container '
                    >
                        {banners.map((banner, index) => (
                            <SwiperSlide key={index}>
                                <div className='image-container  ' style={{ position: 'relative' }}>
                                    <img src={banner.image} alt="banner" className='image-container' />
                                    <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}></div>
                                    <h2 className='h2-text-header1  slide-title' style={{ transform: 'translate(-50%, -50%)', color: 'white', }}>{banner.title}</h2>
                                </div>
                            </SwiperSlide>
                        ))}

                        <div className='nav-buttons-cl' >
                            <NavButton />
                        </div>

                    </Swiper>

                </section >
            </section >
      <section style={{ display: 'flex', justifyContent: 'center' }}>
        <section className="containner">
          <DotRing />
          <div className="main_about_sg-fin">
            


            
              <section className='main-about-sec-n'>
                
                <section className='tab-wrap-ab'>


                  <div className='div-text-w-im-mb'>
                    <img src={bgIllus} alt="vecter" className='vc-im' />

                    <div className='bg-box-ab'>
                      <img src={tc} alt='fintech' className="tec-im-ab" />
                    </div>

                  </div>



                  <div className='div-text-w-ab'>
                    {/* <p className='p-text-new-para'><span style={{ color: '#1961ac', fontSize: '25px', fontWeight: '600' }}> Based in Delhi-NCR</span>, India, the Company provides financing solutions to channel partners (Dealers, Distributors, Retailers, Buyers etc.) of Indian Corporates at competitive rates of 10-13% per annum to grow their business and that of their business partner.</p> */}
                    <p className='p-text-new-para'> <span style={{ color: '#1961ac', fontSize: '25px', fontWeight: '600' }}>Established in 1994</span>, SG Finserve Limited (SGFL) focuses on revolutionizing Supply Chain Financing solutions. SGFL is a RBI registered Non- banking Finance Company (NBFC) providing inclusive business financing solutions to channel partners- Dealers, Distributors, Retailers, Buyers, Suppliers, Transporters/ Logistics etc. of Indian Corporates by all-round use of technology.</p>
                    {/* <p className='p-text-new-para'>SGFL is accredited with CRISIL AA rating for its long-term debt and A1+ for its short-term debt & commercial paper, making it a strong and reliable financial institution.</p> */}
                  </div>

                  <div className='div-text-w-im'>
                    <img src={bgIllus} alt="vecter" className='vc-im' />

                    <div className='bg-box-ab'>
                      <img src={tc} alt='fintech' className="tec-im-ab" />
                    </div>

                  </div>

                </section>



                {/* <section className='tab-wrap-ab'>


                  <div className='div-text-w-im'>
                    <img src={bgIllus} alt="vecter" className='vc-im' />

                    <div className='bg-box-ab'>
                      <img src={tc} alt='fintech' className="tec-im-ab" />
                    </div>

                  </div>

                  <div className='div-text-w-ab'>
                    <p className='p-text-new-para'>Established in 1994 and was a SEBI registered broker which ceased to be as such in the year 2008. Thereafter, the company started carrying out the operation of trading into the equity shares of the listed companies with its own funds. Then, in the year 2018, the company applied for the registration as non-banking financial institution with the reserve bank of india and has obtained the certificate vide no. N-14.03416 dated 16th may, 2018. The equity shares of the company are currently listed on BSE w.e.f. July 8, 2015. It is to be noted that before listing at BSE, the shares were also listed on Delhi stock exchange, jaipur stockexchange and ahmedabad stock exchange.</p>
                  </div>

                </section> */}
              </section>
           

            <section className="about-element">
              <div style={{ textAlign: 'center' }}>
                <div data-aos="zoom-in" data-aos-duration='800' className="about-element-text about-text-box">
                  {/* <h2 style={{ wordSpacing: '1px', letterSpacing: '0.2px' }}>
                    About the Company

                  </h2>
                  <h5 style={{ wordSpacing: "2px", wordBreak: 'break-all', letterSpacing: '0.2px' }}>
                    Streamlining the Supply Chain Financing and optimizing the working capital cycle,
                    ensuring Financial Inclusion for Indian MSMEs by all-round use of technology.
                  </h5>
                  <p
                    style={{
                      paddingTop: "20px",
                      marginTop: "20px",
                      borderTop: "3px solid #1961ac",
                      wordSpacing: '2px',
                      wordBreak: 'break-all',
                      letterSpacing: '0.2px'

                    }}
                  >
                    Based in Delhi-NCR, India, the Company provides financing
                    solutions to channel partners (Dealers, Distributors,
                    Retailers, Buyers etc.) of Indian Corporates at competitive
                    rates of 10-13% per annum to grow their business and that of
                    their business partner.



                    SGFL is accredited with CRISIL AA rating for its long-term
                    debt and A1+ for its short-term debt & commercial paper,
                    making it a strong and reliable financial institution.

                    Established in 1994 and was a SEBI registered broker which
                    ceased to be as such in the year 2008. Thereafter, the
                    company started carrying out the operation of trading into
                    the equity shares of the  listed  companies with its own
                    funds. Then, in the year 2018, the company applied for the
                    registration as non-banking financial institution with the
                    reserve bank of india and has obtained the certificate vide
                    no. N-14.03416 dated 16th may, 2018. The equity shares of
                    the company are currently listed on BSE w.e.f. July 8, 2015.
                    It is to be noted that before listing at BSE, the shares
                    were also listed on Delhi stock exchange, jaipur stockexchange and ahmedabad
                    stock exchange.
                  </p> */}


                  {/* <p
                    style={{
                      paddingTop: '10px',
                      wordSpacing: '3px',
                      wordBreak: 'break-all',
                      letterSpacing: '0.2px'

                    }}
                  >
                    Established in 1994, SG Finserve Limited (SGFL) focuses on revolutionizing Supply
                    Chain Financing solutions. SGFL is a RBI registered Non- banking Finance
                    Company (NBFC) providing inclusive business financing solutions to channel
                    partners- Dealers, Distributors, Retailers, Buyers, Suppliers, Transporters/ Logistics
                    etc. of Indian Corporates by all-round use of technology.
                  </p> */}
                </div>

                <section className='main-tech-so'>

                  <div className="">

                    <div className='main-tech-f' >
                      <div className='in-tech-so'>
                        <h2 style={{ textAlign: 'left', height: '70px', width: 'fit-content' }} className='h2-text-new-b'>Technology Solutions</h2>
                        <p style={{ textAlign: 'left' }} className='p-text-new-para' >
                          Our supply chain financing solutions can leverage our partners all the benefits of advanced technology without incurring any capital outlay or maintenance costs. We also enable digital transformation and help businesses streamline their operations for optimal efficiency.
                        </p>
                      </div>
                      <img src={tecSO} alt="Technology Solutions" className="tech-so-im" />
                    </div>

                    <div style={{ marginTop: '70px', marginBottom: '50px' }} className='main-tech-f'  >
                      <img src={tecSO2} alt="Technology Solutions" className="tech-so-im-2" />
                      <div className='in-tech-so-2'>
                        <h2 style={{ textAlign: 'left', height: '70px', width: 'fit-content' }} className='h2-text-new-b'>Financing Solutions</h2>
                        <p style={{ textAlign: 'left' }} className='p-text-new-para' >
                          Our money lending and factoring services empowers MSMEs to unlock their cash flow potential and take control of their finances. By unlocking capital, we enable businesses to invest in growth and build a thriving enterprise that drives success.
                        </p>
                      </div>

                    </div>











                    {/* <div data-aos="zoom-in" data-aos-duration='800' className="tech-solution-inside" >

                      <h2 style={{ wordSpacing: "2px", wordBreak: 'break-all', marginTop: '20px', letterSpacing: '0.2px' }}>Financing Services</h2>

                      <img src={Fin} style={{ marginTop: '20px', width: '300px' }} alt="Financing Solutions" />


                      <p style={{
                        paddingTop: '30px',
                        wordSpacing: '2px',
                        wordBreak: 'break-all',
                        letterSpacing: '0.2px'

                      }}>Our money lending and factoring services empowers MSMEs to unlock their cash flow potential and take control of their finances. By unlocking capital, we enable businesses to invest in growth and build a thriving enterprise that drives success.</p>

                    </div> */}
                  </div>

                </section>








                <div id="board" style={{ textAlign: 'left' }} data-aos="fade-out-up">
                  <Directer />
                </div>




                <div id="team" data-aos="fade-out-up">
                  <Team />
                </div>

                <div id="bank">
                  <BankingPartners />
                </div>

                <div id="business">
                  <Bussiness />
                </div>

                <div id="life" style={{ textAlign: 'left' }}>
                  <Careers />
                </div>



              
              </div>



            </section>









            {/*<div id="mission" data-aos="zoom-in-up" data-aos-duration='800'>
              <Misson_Visson />
            </div>*/}


            {/* <MissionTextBox /> */}

            {/* <Diverse /> */}

            {/* <GrowthJourny /> */}

            {/* <Promoter /> */}
            {/* <div id="finance" data-aos="fade-out-up">
              <PositionText />
            </div>
            */}
            <div id="solution" data-aos="fade-out-up">
              <SolutionText />
            </div>




            <div id="priority" data-aos="fade-out-up">
              <Priority />
            </div>




            {/* <RevenueGraph /> */}

            <CareerInAbout />

            <div>
              {/* <ReactWhatsapp number="+919791028585" message="Hello World!!!" /> */}
              <FloatingWhatsApp phoneNumber="919791028585" accountName="Customer care" />
            </div>


            <Footer />
          </div>
        </section >
      </section >
    </>
  );
};

export default About;
