import React from 'react'
import Navbar from '../Navbar';
import Footer from '../Footer';
import investerBanner from '../../assests/investor relationship/handshake.webp';
import '../../hamcss/investor.css';
import { useState } from 'react'
import { FaRegFilePdf } from "react-icons/fa";
import { Link } from 'react-router-dom';
import fy24_25PB_sept from '../../assests/sgPdf/9. Notice/FY 2024-25/Postal ballot notice_05.09.2024 (1).pdf';
import fy24_25BM_sept from '../../assests/sgPdf/9. Notice/FY 2024-25/Notice of Board Meeting _05-09-2024.pdf';
import fy24_25notice from '../../assests/sgPdf/9. Notice/FY 2024-25/Notice of 30Th AGM.pdf';
import fy24_25BM from '../../assests/sgPdf/9. Notice/FY 2024-25/BM Intimation 21.05.2024.pdf';
import fy24_25BM_july from '../../assests/sgPdf/9. Notice/FY 2024-25/BM Intimation 10.07.2024.pdf';
import fy24_25TW1 from '../../assests/sgPdf/9. Notice/FY 2024-25/Trading Window Closure March 2024.pdf';
import fy24_25TW2 from '../../assests/sgPdf/9. Notice/FY 2024-25/Trading Window Closure 30.06.2024.pdf';
import fy24_25TWS from '../../assests/sgPdf/9. Notice/FY 2024-25/Trading Window Closure September 2024.pdf';
import fy24_25BM_oct from '../../assests/sgPdf/9. Notice/FY 2024-25/BM Intimation 15.10.2024.pdf';
import fy23_24AGM from '../../assests/sgPdf/9. Notice/FY 2023-24/Notice of 29th AGM.pdf';
import fy23_24BM_may from '../../assests/sgPdf/9. Notice/FY 2023-24/BM/BM Intimation 11.05.2023.pdf';
import fy23_24BM_oct from '../../assests/sgPdf/9. Notice/FY 2023-24/BM/BM Intimation 18.10.2023.pdf';
import fy23_24BM_july from '../../assests/sgPdf/9. Notice/FY 2023-24/BM/BM Intimation 15.07.2023.pdf';
import fy23_24BM_jan from '../../assests/sgPdf/9. Notice/FY 2023-24/BM/BM Intimation 19.01.2024.pdf';
import fy23_24Postal from '../../assests/sgPdf/9. Notice/FY 2023-24/c. Postal Ballot/Notice.pdf';
import fy23_24TDW_dec from '../../assests/sgPdf/9. Notice/FY 2023-24/d. Trading Window/Trading_Window_Closure dec.pdf'
import fy23_24TDW_sept from '../../assests/sgPdf/9. Notice/FY 2023-24/d. Trading Window/Trading_Window_Closure Sept.pdf'
import fy23_24TDW_june from '../../assests/sgPdf/9. Notice/FY 2023-24/d. Trading Window/Trading_Window_Closure_June_SG_Finserve_Limited[1].pdf'
import fy23_24TDW_MARCH from '../../assests/sgPdf/9. Notice/FY 2023-24/d. Trading Window/Trading Window Closure 01.04.2024.pdf';

import fy22_23AGM from '../../assests/sgPdf/9. Notice/FY 2022-23/a. AGM/30.09.2022.pdf'
import fy22_23bm_aug from '../../assests/sgPdf/9. Notice/FY 2022-23/b. BM/Intimation of BM 10.08.2022.pdf';
import fy22_23bm_july from '../../assests/sgPdf/9. Notice/FY 2022-23/b. BM/Intimation of BM 25.07.2022.pdf';
import fy22_23bm_may from '../../assests/sgPdf/9. Notice/FY 2022-23/b. BM/Intimation of BM 26.05.2022.pdf';
import fy22_23Pos_Oct from '../../assests/sgPdf/9. Notice/FY 2022-23/c. Postal Ballot/28.10.2022.pdf';
import fy22_23Pos_Oct1 from '../../assests/sgPdf/9. Notice/FY 2022-23/c. Postal Ballot/Corrigendum 28.10.2022.pdf';
import fy22_23Pos_july from '../../assests/sgPdf/9. Notice/FY 2022-23/c. Postal Ballot/25.07.2022.pdf';
import fy22_23Pos_july1 from '../../assests/sgPdf/9. Notice/FY 2022-23/c. Postal Ballot/Corrigendum 25.07.2022.pdf';
import fy22_23TDW_dec from '../../assests/sgPdf/9. Notice/FY 2022-23/d. Trading Window/Trading Window Closure 31.12.2022 SG Finserve Limited.pdf'
import fy22_23TDW_sep from '../../assests/sgPdf/9. Notice/FY 2022-23/d. Trading Window/Trading Window Closure 30.09.2022 MSL.pdf'
import fy22_23TDW_july from '../../assests/sgPdf/9. Notice/FY 2022-23/d. Trading Window/Trading window closure 01.07.2022.pdf'
import fy22_23TDW_March from '../../assests/sgPdf/9. Notice/FY 2022-23/d. Trading Window/Trading window clsoure 01.04.2022.pdf'

import fy21_22AGM from '../../assests/sgPdf/9. Notice/FY 2021-22/a. AGM/30.09.2021.pdf';
import fy21_22bm_Nov from '../../assests/sgPdf/9. Notice/FY 2021-22/b. BM/BM Intimation 08.11.2021.pdf';
import fy21_22bm_aug from '../../assests/sgPdf/9. Notice/FY 2021-22/b. BM/BM Intimation 05.08.2021.pdf';
import fy21_22bm_june from '../../assests/sgPdf/9. Notice/FY 2021-22/b. BM/BM Intimation 24.06.2021.pdf';
import fy21_22bm_feb from '../../assests/sgPdf/9. Notice/FY 2021-22/b. BM/BM Intimation 10.02.2022.pdf';
import fy21_22bm_jan from '../../assests/sgPdf/9. Notice/FY 2021-22/b. BM/BM Intimation 25.01.2022.pdf';
import fy21_22TWD_oct from '../../assests/sgPdf/9. Notice/FY 2021-22/d. Trading Window/Trading Window Closure 01.10.2021.pdf'
import fy21_22TWD_july from '../../assests/sgPdf/9. Notice/FY 2021-22/d. Trading Window/Trading Window Closure 01.07.2021.pdf'
import fy21_22TWD_march from '../../assests/sgPdf/9. Notice/FY 2021-22/d. Trading Window/Trading Window Closure 01.04.2021.pdf';
import fy21_22TWD_Dec from '../../assests/sgPdf/9. Notice/FY 2021-22/d. Trading Window/Trading Window Closure 01.01.2022.pdf';

import fy20_21AGM from '../../assests/sgPdf/9. Notice/FY 2020-21/a. AGM/05.11.2020.pdf';
import fy20_21bm_nov from '../../assests/sgPdf/9. Notice/FY 2020-21/b. BM/BM Intimation 10.11.2020.pdf';
import fy20_21bm_aug from '../../assests/sgPdf/9. Notice/FY 2020-21/b. BM/BM Intimation 19.08.20.pdf';
import fy20_21bm_June from '../../assests/sgPdf/9. Notice/FY 2020-21/b. BM/BM Intimation 27.06.2020.pdf';
import fy20_21bm_feb from '../../assests/sgPdf/9. Notice/FY 2020-21/b. BM/BM Intimation 11.02.2021.pdf';

import fy20_21TWD_jan from '../../assests/sgPdf/9. Notice/FY 2020-21/d. Trading Window/Trading window closure 01.01.2021.pdf';
import fy20_21TWD_April from '../../assests/sgPdf/9. Notice/FY 2020-21/d. Trading Window/Trading window closure 06.04.2020.pdf';
import fy20_21TWD_july from '../../assests/sgPdf/9. Notice/FY 2020-21/d. Trading Window/Trading window closure 01.07.2020.pdf';
import fy20_21TWD_oct from '../../assests/sgPdf/9. Notice/FY 2020-21/d. Trading Window/Trading window closure 01.10.2020.pdf';

import fy19_20AGM from '../../assests/sgPdf/9. Notice/FY 2019-20/a. AGM/22.08.19.pdf';
import fy19_20bm_nov from '../../assests/sgPdf/9. Notice/FY 2019-20/b. BM/BM Intimation 07.11.2019.pdf';
import fy19_20bm_july from '../../assests/sgPdf/9. Notice/FY 2019-20/b. BM/BM Intimation 22.07.2019.pdf';
import fy19_20bm_may from '../../assests/sgPdf/9. Notice/FY 2019-20/b. BM/BM Intimation 18.05.2019.pdf';
import fy19_20bm_dec from '../../assests/sgPdf/9. Notice/FY 2019-20/b. BM/BM Intimation 31.01.2020.pdf';
import fy19_20TDW_dec from '../../assests/sgPdf/9. Notice/FY 2019-20/d. Trading Window/Trading window closure 01.01.20.pdf';
import fy19_20TDW_sep from '../../assests/sgPdf/9. Notice/FY 2019-20/d. Trading Window/Trading window closure 01.10.2019.pdf';
import fy19_20TDW_mrch from '../../assests/sgPdf/9. Notice/FY 2019-20/d. Trading Window/Trading window closure 03.04.19.pdf';
import fy19_20TDW_july from '../../assests/sgPdf/9. Notice/FY 2019-20/d. Trading Window/Trading window closure 29.06.19.pdf';

import fy18_19AGM_Book from '../../assests/sgPdf/9. Notice/FY 2018-19/a. AGM/Book closure & AGM 27.09.2018.pdf';
import fy18_19AGM_notice from '../../assests/sgPdf/9. Notice/FY 2018-19/a. AGM/Notice 27.09.2018.pdf';
import fy18_19bm_nov from '../../assests/sgPdf/9. Notice/FY 2018-19/b. BM/BM Intimation 12.11.18.pdf';
import fy18_19bm_aug from '../../assests/sgPdf/9. Notice/FY 2018-19/b. BM/BM Intimation 09.08.18.pdf';
import fy18_19bm_may from '../../assests/sgPdf/9. Notice/FY 2018-19/b. BM/BM Intimation 28.05.18, TWC.pdf';
import fy18_19TDW_dec from '../../assests/sgPdf/9. Notice/FY 2018-19/d. Trading Window/Trading window closure 22.01.19.pdf';

import fy17_18AGM from '../../assests/sgPdf/9. Notice/FY 2017-18/a. AGM/Notice 07.09.2017.pdf';
import fy17_18bm_nov from '../../assests/sgPdf/9. Notice/FY 2017-18/b. BM/BM 14.11.2017.pdf';
import fy17_18bm_feb from '../../assests/sgPdf/9. Notice/FY 2017-18/b. BM/BM 12.02.2018, TWC 05.02.18.pdf';
import fy17_18bm_aug from '../../assests/sgPdf/9. Notice/FY 2017-18/b. BM/BM 14.08.2017.pdf';
import fy17_18bm_may from '../../assests/sgPdf/9. Notice/FY 2017-18/b. BM/BM 29.05.17.pdf';

import fy16_17AGM from '../../assests/sgPdf/9. Notice/FY 2016-17/a. AGM/22.09.2016.pdf';
import fy16_17bm_feb from '../../assests/sgPdf/9. Notice/FY 2016-17/b. BM/09.02.2017.pdf';
import fy16_17bm_nov from '../../assests/sgPdf/9. Notice/FY 2016-17/b. BM/10.11.2016.pdf';
import fy16_17bm_july from '../../assests/sgPdf/9. Notice/FY 2016-17/b. BM/11.08.2016.pdf';
import fy16_17bm_march from '../../assests/sgPdf/9. Notice/FY 2016-17/b. BM/25.04.2016.pdf';
import fy16_17bm_april from '../../assests/sgPdf/9. Notice/FY 2016-17/b. BM/10.05.2016.pdf';



const Notices = () => {

    const [selectedOption, setSelectedOption] = useState("option"); // State to track selected option

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value); // Update selected option when changed
    };


    return (
        <>

            <Navbar />
            <section>
                <div className="BreadArea" style={{
                    //  for /beta server 
                    backgroundImage: `url(${investerBanner})`,
                    // backgroundImage: "url('images/Bread.png')",
                }}>
                    <div className="container">
                        <h2>Notice </h2>
                    </div>
                </div>
            </section>


            <section className="main-an">

                <div className="main-an-box">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h2 className='h2-text'>Notice </h2>
                        <div>
                            <select className="select-class" onChange={handleOptionChange}>
                                <option value="option">FY 2024-2025</option>
                                <option value="option1">FY 2023-2024</option>
                                <option value="option2">FY 2022-2023</option>
                                <option value="option3">FY 2021-2022</option>
                                <option value="option4">FY 2020-2021</option>
                                <option value="option5">FY 2019-2020</option>
                                <option value="option6">FY 2018-2019</option>
                                <option value="option7">FY 2017-2018</option>
                                <option value="option8">FY 2016-2017</option>

                            </select>
                        </div>
                    </div>

                    <div style={{ marginTop: '50px' }}>
                        {/* 24-25 */}

                        {selectedOption === "option" && (
                            <>
                                
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy24_25notice} target='blank'>  <h6>Notice of 30th AGM</h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy24_25BM_sept} target='blank'>  <h6>Notice of Board Meeting 05-09-2024</h6> </a>
                                </div>
                                
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy24_25BM} target='blank'>  <h6>BM Intimation 21.05.2024</h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy24_25BM_july} target='blank'>  <h6>BM Intimation 01.07.2024</h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy24_25TW1} target='blank'>  <h6>Trading Window Closure March 2024</h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy24_25TW2} target='blank'>  <h6>Trading Window Closure 30.06.2024</h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy24_25PB_sept} target='blank'>  <h6>Postal ballot notice_05.09.2024</h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy24_25TWS} target='blank'>  <h6>Trading Window Closure September 2024</h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy24_25BM_oct} target='blank'>  <h6>BM Intimation 15.10.2024</h6> </a>
                                </div>
                                
                                


                            </>

                        )}
                        {/* 23-24 */}

                        {selectedOption === "option1" && (
                            <>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy23_24AGM} target='blank'>  <h6>Annual general meeting </h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy23_24BM_jan} target='blank'>  <h6>Board Meeting Jan </h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy23_24BM_may} target='blank'>  <h6>Board Meeting May </h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy23_24BM_july } target='blank'>  <h6>Board Meeting July </h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy23_24BM_oct} target='blank'>  <h6>Board Meeting Oct </h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy23_24Postal} target='blank'> <h6>Postal ballot</h6> </a>
                                </div>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy23_24TDW_dec} target='blank'> <h6>Trading window closure December </h6> </a>
                                </div>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy23_24TDW_sept} target='blank'> <h6>Trading window closure September</h6> </a>
                                </div>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy23_24TDW_june} target='blank'> <h6>Trading window closure June</h6> </a>
                                </div>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy23_24TDW_MARCH} target='blank'> <h6>Trading window closure March</h6> </a>
                                </div>


                            </>

                        )}

                        {/* 22-23 */}


                        {selectedOption === "option2" && (
                            <>
                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy22_23AGM} target="blank"> <h6>Annual General Meeting</h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy22_23bm_aug} target='blank'> <h6>Board Meeting August </h6> </a>
                                </div>

                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy22_23bm_july} target='blank'> <h6>Board Meeting July </h6> </a>
                                </div>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy22_23bm_may} target='blank'> <h6>Board Meeting May </h6> </a>
                                </div>



                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy22_23Pos_Oct} target='blank'> <h6>Postal Ballot October</h6> </a>
                                </div>

                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy22_23Pos_Oct1} target='blank'> <h6>Postal Ballot Corrigendum October </h6> </a>
                                </div>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy22_23Pos_july} target='blank'> <h6>Postal Ballot July</h6> </a>
                                </div>

                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy22_23Pos_july1} target='blank'> <h6>Postal Ballot Corrigendum July</h6> </a>
                                </div>



                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy22_23TDW_dec} target='blank'> <h6>Trading window closure December </h6> </a>
                                </div>


                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy22_23TDW_sep} target='blank'> <h6>Trading window closure September </h6> </a>
                                </div>


                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy22_23TDW_july} target='blank'> <h6>Trading window closure June </h6> </a>
                                </div>


                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy22_23TDW_March} target='blank'> <h6>Trading window closure March </h6> </a>
                                </div>


                            </>
                        )}


                        {/* 21-22 */}

                        {selectedOption === "option3" && (
                            <>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy21_22AGM} target='blank'> <h6> </h6> Annual General Meeting</a>
                                </div>

                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy21_22bm_Nov} target='blank'> <h6> </h6> Board Meeting November</a>
                                </div>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy21_22bm_aug} target='blank'> <h6> </h6> Board Meeting August</a>
                                </div>

                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy21_22bm_june} target='blank'> <h6> </h6> Board Meeting June</a>
                                </div>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy21_22bm_feb} target='blank'> <h6> </h6> Board Meeting February</a>
                                </div>

                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy21_22bm_jan} target='blank'> <h6> </h6> Board Meeting January</a>
                                </div>


                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={"#"} target='blank'> <h6> </h6> Postal Ballot</a>
                                </div>



                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy21_22TWD_Dec} target='blank'> <h6> </h6> Trading window closure December</a>
                                </div>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy21_22TWD_oct} target='blank'> <h6> </h6> Trading window closure October</a>
                                </div>

                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy21_22TWD_july} target='blank'> <h6> </h6> Trading window closure July</a>
                                </div>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy21_22TWD_march} target='blank'> <h6> </h6> Trading window closure March</a>
                                </div>

                            </>
                        )}


                        {/* 20-21 */}

                        {selectedOption === "option4" && (
                            <>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy20_21AGM} target='blank'> <h6> </h6> Annual General Meeting</a>
                                </div>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy20_21bm_nov} target='blank'> <h6> </h6> Board Meeting November</a>
                                </div>

                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy20_21bm_aug} target='blank'> <h6> </h6> Board Meeting August</a>
                                </div>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy20_21bm_June} target='blank'> <h6> </h6> Board Meeting June</a>
                                </div>

                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy20_21bm_feb} target='blank'> <h6> </h6> Board Meeting February</a>
                                </div>


                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={"#"} target='blank'> <h6> </h6> Postal Ballot</a>
                                </div>

                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy20_21TWD_oct} target='blank'> <h6> </h6> Trading window closure October</a>
                                </div>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy20_21TWD_july} target='blank'> <h6> </h6> Trading window closure July</a>
                                </div>

                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy20_21TWD_April} target='blank'> <h6> </h6> Trading window closure April</a>
                                </div>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy20_21TWD_jan} target='blank'> <h6> </h6> Trading window closure January</a>
                                </div>

                            </>
                        )}

                        {/* 19-20 */}

                        {selectedOption === "option5" && (
                            <>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy19_20AGM} target='blank'> <h6> </h6> Annual General Meeting</a>
                                </div>

                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy19_20bm_dec} target='blank'> <h6> </h6> Board Meeting December</a>
                                </div>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy19_20bm_nov} target='blank'> <h6> </h6> Board Meeting November</a>
                                </div>

                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy19_20bm_may} target='blank'> <h6> </h6> Board Meeting May </a>
                                </div>



                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={'#'} target='blank'> <h6> </h6> Postal Ballot</a>
                                </div>

                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy19_20TDW_dec} target='blank'> <h6> </h6> Trading window closure December</a>
                                </div>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy19_20TDW_sep} target='blank'> <h6> </h6> Trading window closure Septmber</a>
                                </div>

                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy19_20TDW_july} target='blank'> <h6> </h6> Trading window closure July</a>
                                </div>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy19_20TDW_mrch} target='blank'> <h6> </h6> Trading window closure  March</a>
                                </div>

                            </>
                        )}

                        {/* 18-19 */}

                        {selectedOption === "option6" && (
                            <>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy18_19AGM_Book} target='blank'> <h6> </h6> Annual General Meeting Book closure</a>
                                </div>

                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy18_19AGM_notice} target='blank'> <h6> </h6> Annual General Meeting </a>
                                </div>



                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy18_19bm_nov} target='blank'> <h6> </h6> Board Meeting</a>
                                </div>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy18_19bm_aug} target='blank'> <h6> </h6> Board Meeting</a>
                                </div>

                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy18_19bm_may} target='blank'> <h6> </h6> Board Meeting</a>
                                </div>


                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={'#'} target='blank'> <h6> </h6> Postal Ballot</a>
                                </div>


                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy18_19TDW_dec} target='blank'> <h6> </h6> Trading window closure December</a>
                                </div>

                            </>
                        )}

                        {/* 17-18 */}


                        {selectedOption === "option7" && (
                            <>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy17_18AGM} target='blank'> <h6> </h6> Annual General Meeting</a>
                                </div>


                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy17_18bm_feb} target='blank'> <h6> </h6> Board Meeting</a>
                                </div>

                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy17_18bm_nov} target='blank'> <h6> </h6> Board Meeting November</a>
                                </div>

                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy17_18bm_aug} target='blank'> <h6> </h6> Board Meeting August</a>
                                </div>

                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy17_18bm_may} target='blank'> <h6> </h6> Board Meeting</a>
                                </div>



                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={'#'} target='blank'> <h6> </h6> Postal Ballot</a>
                                </div>

                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={'#'} target='blank'> <h6> </h6> Trading window closure</a>
                                </div>
                            </>
                        )}
                        {/* 16-17 */}

                        {selectedOption === "option8" && (
                            <>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy16_17AGM} target='blank'> <h6> </h6> Annual General Meeting</a>
                                </div>

                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy16_17bm_feb} target='blank'> <h6> </h6> Board Meeting February 2017</a>
                                </div>

                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy16_17bm_nov} target='blank'> <h6> </h6> Board Meeting November </a>
                                </div>

                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy16_17bm_july} target='blank'> <h6> </h6> Board Meeting July</a>
                                </div>

                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy16_17bm_april} target='blank'> <h6> </h6> Board Meeting April</a>
                                </div>

                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy16_17bm_march} target='blank'> <h6> </h6> Board Meeting March</a>
                                </div>

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={"#"} target='blank'> <h6> </h6> Postal Ballot</a>
                                </div>

                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={'#'} target='blank'> <h6> </h6> Trading window closure</a>
                                </div>
                            </>
                        )}




                    </div>
                </div>

            </section>



            <Footer />


        </>
    )
}

export default Notices