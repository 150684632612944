import React from 'react';
import { useSwiper } from 'swiper/react';
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";

const NavButton = () => {
    const swiper = useSwiper();

    return (
        <div style={{ display: 'flex', gap: '20px', }}>
            <button style={{ width: '3rem', height: '3rem', borderRadius: '9999px', color: 'black', border: '1px solid black', justifyContent: 'center', alignItems: 'center' }} onClick={() => swiper.slidePrev()}>
                <FaArrowLeft size={20} />
            </button>
            <button style={{ width: '3rem', height: '3rem', borderRadius: '9999px', color: 'black', border: '1px solid black', justifyContent: 'center', alignItems: 'center' }} onClick={() => swiper.slideNext()}>
                <FaArrowRight size={20} />
            </button>
        </div>
    );
};

export default NavButton;
