import React from 'react'
import Navbar from '../Navbar';
import Solution_cards from '../solution/Solution_cards';
import Footer from '../Footer';
import '../../hamcss/dealer.css';
import { useEffect } from 'react';
import AOS from 'aos';
import dealerBanner from '../../assests/home/top_banner/dealerImage.webp';

// import ReactWhatsapp from "react-whatsapp";
import { FloatingWhatsApp } from 'react-floating-whatsapp';



const Dealer = () => {


  useEffect(() => {
    AOS.init();

  })


  return (
    <>

      <div className='main_dealer_container' >
        <Navbar />
        {/* <!--start banner slider--> */}
        <section>
          <div className="BreadArea" style={{
            backgroundImage: `url(${dealerBanner})`,
            objectFit: 'cover',
            // objectFit: 'fill'
          }}>
            <div className="container">
              <h2 style={{ wordSpacing: "2px", letterSpacing: '0.3px' }}>Dealer/Distributor Finance </h2>

            </div>
          </div>
        </section>
        <div data-aos="fade-up" data-aos-duration="800" className='dealer_child_box' >
          <div className='dealer_text_container'>
            <h1 style={{ fontWeight: '600' }} className='dealer_text_heading_2' >What is  Dealer Finance?</h1>
            {/* <h5 className='dealer_text_heading'>Empowering Corporates to Support Their Dealers/Distributors to Catalyse Sales.</h5> */}
            <p style={{ }} className='dealer_text_para'>
              Dealer Financing product offered by SG Finserve is a specialized financial solution
              designed to support dealerships in acquiring and managing inventory. These
              products cater to various industries such as metals, automotive, building materials,
              agriculture machinery, consumer durables, and more.
            </p>

            <p style={{  }} className='dealer_text_para'>Distributors and dealers form an important link in the supply chain network. We aim to provide need-based financial assistance to such distributors and dealers via Line of Credit
              The funding is secured by the purchasesmade by the dealer or distributor from the Corporate
              This gives dealers and distributors access to additional working capital, enabling them to drive business growth and increase the sales penetration for the Corporate
            </p>

            <div>
              <h4 style={{ textAlign: "left" }}>Here&#39;s a brief overview of dealer financing product offered by SG Finserve:</h4>
              <ul>
                <li className='dealer_text_para'><b>Inventory Financing: </b>SG Finserve provides financing to dealerships for the
                  purchase of inventory, including vehicles, machinery, equipment, and other
                  goods. This funding helps dealers maintain sufficient stock levels to meet
                  customer demand without tying up their working capital.
                </li>

                <li className='dealer_text_para'><b>Flexible Terms: </b>SG Finserve offers flexible terms tailored to the specific needs
                  of dealerships. This includes competitive interest rates, customizable
                  repayment schedules, and loan amounts based on the value of the inventory. </li>
                <li className='dealer_text_para'><b> Quick Approval Process:</b> SG Finserve has a streamlined application and
                  approval processes to provide dealerships with fast access to financing. This
                  enables dealers to seize opportunities, such as purchasing seasonal inventory
                  or taking advantage of manufacturer incentives/ cash discount.</li>
                <li className='dealer_text_para'><b>Collateral- free Financing: </b>Dealer financing by SG Finserve is secured by the
                  inventory itself, thus collateral free loan for the dealerships. </li>



              </ul>
              <p className='dealer_text_para'>Overall, dealer-financing product offered by SG Finserve plays a vital role in
                supporting the growth and success of dealerships by providing them with the
                necessary capital and resources to manage effectively their inventory and
                operations.</p>
            </div>

          </div>
        </div>
        <Solution_cards />


        {/* <ReactWhatsapp number="+919791028585" message="Hello World!!!" /> */}
        <FloatingWhatsApp phoneNumber="919791028585" accountName="Customer Care" />



        <Footer />
      </div>

    </>

  )
}

export default Dealer