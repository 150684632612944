
import '../hamcss/contactform.css';
import '../hamcss/team.css';
import con from '../assests/cont.webp';

const ContactForm = () => {



    return (
        <>



            <div class="app-form">
                <div>
                    <h2 className="headings">Contact form</h2>
                    <div class="app-form-group">
                        <input class="app-form-control" placeholder="NAME" />
                    </div>
                    <div class="app-form-group">
                        <input class="app-form-control" placeholder="EMAIL" />
                    </div>
                    <div class="app-form-group">
                        <input class="app-form-control" placeholder="CONTACT NO" />
                    </div>
                    <div class="app-form-group ">
                        <textarea class="app-form-control" placeholder="MESSAGE" />
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div style={{ marginTop: '10px', fontSize: '14px', fontWeight: '500', }} >
                        <input type="checkbox" /> I declare that the information I have provided is accurate & complete to the best of my knowledge. I hereby authorize SG Finserve Ltd. and its affiliates to call, email, send a text through the Short Messaging Service (SMS) and/or WhatsApp  me in relation to any of their products. The consent herein shall override any registration for DNC/NDNC.
                    </div>
                </div>
                <div style={{ marginTop:'15px' }}>
                    <button class="app-form-button">SEND</button>
                </div>
            </div>







        </>
    )
}

export default ContactForm