import '../../hamcss/bussiness.css';
import { useEffect } from 'react';
import p1 from '../../assests/otherVector/alpha.png'
import p2 from '../../assests/otherVector/apolloPipe.jpg'
import p3 from '../../assests/otherVector/apolloTube.png'
import p4 from '../../assests/otherVector/bajaj.png'
import p5 from '../../assests/otherVector/balco.jpg'
import p6 from '../../assests/otherVector/deNeers.png'
import p7 from '../../assests/otherVector/dorset.png'
import p8 from '../../assests/otherVector/hindustanZinc.png'
import p9 from '../../assests/otherVector/jindal.png'
import p10 from '../../assests/otherVector/kajaria.png'
import p11 from '../../assests/otherVector/kisloskar.png'
import p12 from '../../assests/otherVector/oppo.png'
import p13 from '../../assests/otherVector/ornat.png'
import p14 from '../../assests/otherVector/prayag.png'
import p15 from '../../assests/otherVector/ramasteel.jpg'
import p16 from '../../assests/otherVector/redington.png'
import p17 from '../../assests/otherVector/shalimar.png'
import p18 from '../../assests/otherVector/shankara.png'
import p19 from '../../assests/otherVector/sharvari.png'
import p20 from '../../assests/otherVector/9a6a1655-8a25-44ac-96e4-47c2f56e586b.jpeg'
import p21 from '../../assests/otherVector/time.png'
import sgMart from '../../assests/otherVector/sgMart.png'
import tm from '../../assests/otherVector/tm.webp';
import bl from '../../assests/otherVector/bal.png';
import amns from '../../assests/otherVector/amns.jpg';
import adani from '../../assests/otherVector/Adani-transformed.webp';
import zetwork from '../../assests/otherVector/zetwork.png';
import inflow from '../../assests/otherVector/inflow.png';
import SuperTron from '../../assests/otherVector/superTron.png';
import p22 from '../../assests/otherVector/mg.png'
import p23 from '../../assests/otherVector/Ashok_Leyland-Logo.wine.png'

import AOS from 'aos';


const Bussiness = () => {



    const images = [
        p2, p3, sgMart, p5, tm, p16, p4, p12, bl, p9, amns, p11, adani, p10, zetwork, inflow, p8, p21, p18, p20, p1, SuperTron, p14, p6, p7, p13, p19,p22,p23
    ];




    useEffect(() => {
        AOS.init();

    })


    return (
        <>

            <section className='main-bussiness'>
                <div>
                    <h2 className='h2-text-b'>Our Business Partners </h2>
                    <p style={{ fontSize: "20px", wordSpacing: '2px', fontWeight: '500', letterSpacing: '0.2px' }}>We have the privilege of working with amazing companies across industries</p>
                </div>

                <div className="business-parners-box">
                    {images.map((image, index) => (
                        <div data-aos="flip-up" data-aos-duration='800' className="image-box-busi" key={index}>
                            <img src={image} alt='partners logo' />
                        </div>
                    ))}
                </div>

            </section>



        </>
    )
}

export default Bussiness