
import Navbar from "../Navbar";
import Footer from "../Footer";
// import "../hamcss/directer.css";
// import ReactWhatsapp from "react-whatsapp";
import { FloatingWhatsApp } from 'react-floating-whatsapp';

import tech from '../../assests/tech.webp';
import Fin from '../../assests/fin.jpg';

import DotRing from '../../component/dot/DotRing';






const DeetailsOfBusiness = () => {
    return (
        <>

            <section style={{ display: 'flex', justifyContent: 'center' }}>
                <section className="containner">
                    <DotRing />
                    <div className="main_about_sg-fin">
                        <Navbar />

                        {/* <!--start banner slider--> */}
                        <section>
                            <div
                                className="BreadArea"
                                style={{
                                    backgroundImage: `url('/images/Bread.png')`,
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                    height: "43vh",
                                }}
                            >
                                <div
                                    data-aos="fade-down"
                                    data-aos-duration="1000"
                                    style={{ marginTop: "30px" }}
                                    className="container"
                                >
                                    <h2 style={{ wordSpacing: '1px', letterSpacing: '0.2px' }}>
                                        Details of Business
                                    </h2>

                                </div>
                            </div>
                        </section>


                        <section className="about-element">
                            <div style={{ textAlign: 'center' }}>
                                <div data-aos="zoom-in" data-aos-duration='800' className="about-element-text about-text-box">
                                    <h2 style={{ wordSpacing: '1px', letterSpacing: '0.2px' }}>
                                        About the Company

                                    </h2>
                                    <h5 style={{ wordSpacing: "2px", wordBreak: 'break-all', letterSpacing: '0.2px' }}>
                                        Streamlining the Supply Chain Financing and optimizing the working capital cycle,
                                        ensuring Financial Inclusion for Indian MSMEs by all-round use of technology.
                                    </h5>
                                    <p
                                        style={{
                                            paddingTop: "20px",
                                            marginTop: "20px",
                                            borderTop: "3px solid #f18620",
                                            wordSpacing: '2px',
                                            wordBreak: 'break-all',
                                            letterSpacing: '0.2px'

                                        }}
                                    >
                                        Based in Delhi-NCR, India, the Company provides financing
                                        solutions to channel partners (Dealers, Distributors,
                                        Retailers, Buyers etc.) of Indian Corporates at competitive
                                        rates of 10-13% per annum to grow their business and that of
                                        their business partner.
                                        SGFL is accredited with CRISIL AA rating for its long-term
                                        debt and A1+ for its short-term debt & commercial paper,
                                        making it a strong and reliable financial institution.
                                        Established in 1994 and was a SEBI registered broker which
                                        ceased to be as such in the year 2008. Thereafter, the
                                        company started carrying out the operation of trading into
                                        the equity shares of the  listed  companies with its own
                                        funds. Then, in the year 2018, the company applied for the
                                        registration as non-banking financial institution with the
                                        reserve bank of india and has obtained the certificate vide
                                        no. N-14.03416 dated 16th may, 2018. The equity shares of
                                        the company are currently listed on BSE w.e.f. July 8, 2015.
                                        It is to be noted that before listing at BSE, the shares
                                        were also listed on Delhi stock exchange, jaipur stockexchange and ahmedabad
                                        stock exchange.
                                    </p>


                                    <div className="tech-solution">
                                        <div data-aos="zoom-in" data-aos-duration='800' className="tech-solution-inside" >

                                            <h2 style={{ wordSpacing: "2px", wordBreak: 'break-all', marginTop: '20px', letterSpacing: '0.2px' }}>Technology Solutions</h2>
                                            <img src={tech} style={{ marginTop: '25px', width: '300px' }} alt="Technology Solutions" />
                                            <p style={{
                                                paddingTop: '10px',
                                                wordSpacing: '2px',
                                                wordBreak: 'break-all',
                                                letterSpacing: '0.2px'

                                            }}>
                                                Our supply chain financing solutions can leverage our partners all the benefits of advanced technology without incurring any capital outlay or maintenance costs. We also enable digital transformation and help businesses streamline their operations for optimal efficiency.
                                            </p>

                                        </div>

                                        <div data-aos="zoom-in" data-aos-duration='800' className="tech-solution-inside" >

                                            <h2 style={{ wordSpacing: "2px", wordBreak: 'break-all', marginTop: '20px', letterSpacing: '0.2px' }}>Financing Services</h2>

                                            <img src={Fin} style={{ marginTop: '20px', width: '300px' }} alt="Financing Solutions" />


                                            <p style={{
                                                paddingTop: '30px',
                                                wordSpacing: '2px',
                                                wordBreak: 'break-all',
                                                letterSpacing: '0.2px'

                                            }}>Our money lending and factoring services empowers MSMEs to unlock their cash flow potential and take control of their finances. By unlocking capital, we enable businesses to invest in growth and build a thriving enterprise that drives success.</p>

                                        </div>
                                    </div>


                                </div>


                                <div>
                                    {/* <ReactWhatsapp number="+919791028585" message="Hello World!!!" /> */}
                                    <FloatingWhatsApp phoneNumber="919791028585" accountName="Customer care" />
                                </div>


                                <Footer />
                            </div>
                        </section>
                    </div>
                </section>
            </section>
        </>
    )
}

export default DeetailsOfBusiness