import React, { useState, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Grid, Pagination } from 'swiper/modules';

import anil from '../../assests/anilKumarBansal.webp';
import AshaAnil from '../../assests/Asha-Anil.webp';
import uprndra from '../../assests/HS-Upendra.webp';
import dukhbandu from '../../assests/Dukhabandhu.webp';
import rahul from '../../assests/rahul-gupta.webp';
import rohan from '../../assests/Rohan gupta.jpg';
import '../../hamcss/directer.css';
import '../../hamcss/team.css';
import { IoMdClose } from "react-icons/io";

import NavButton from '../NavButtons';



import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/pagination';


const Director = () => {




    const [visible, setIsvisible] = useState(false)
    const [visible1, setIsvisible1] = useState(false)
    const [visible2, setIsvisible2] = useState(false)
    const [visible3, setIsvisible3] = useState(false)
    const [visible4, setIsvisible4] = useState(false)
    const [visible5, setIsvisible5] = useState(false)
    const [visible6, setIsvisible6] = useState(false)
    // eslint-disable-next-line no-unused-vars
    const [visible7, setIsvisible7] = useState(false)



    const boxRef = useRef(null);


    const handleOpen = () => {
        setIsvisible(!visible)
    }

    const handleOpen1 = () => {
        setIsvisible1(!visible1)
    }
    const handleOpen2 = () => {
        setIsvisible2(!visible2)
    }

    const handleOpen3 = () => {
        setIsvisible3(!visible3)
    }

    const handleOpen4 = () => {
        setIsvisible4(!visible4)
    }

    const handleOpen5 = () => {
        setIsvisible5(!visible5)
    }


    const handleClickOutside = (event) => {
        if (boxRef.current && !boxRef.current.contains(event.target)) {
            setIsvisible(false);
        }

        if (boxRef.current && !boxRef.current.contains(event.target)) {
            setIsvisible1(false);
        }

        if (boxRef.current && !boxRef.current.contains(event.target)) {
            setIsvisible2(false);
        }

        if (boxRef.current && !boxRef.current.contains(event.target)) {
            setIsvisible3(false);
        }

        if (boxRef.current && !boxRef.current.contains(event.target)) {
            setIsvisible4(false);
        }

        if (boxRef.current && !boxRef.current.contains(event.target)) {
            setIsvisible5(false);
        }


    };

    useEffect(() => {
        // Add event listener when component mounts
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup the event listener when component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);












    return (
        <>

            {/* <section className='directer-b-main-p'>
                <section className="directer-b-main" style={{ marginBottom: "50px" }}>



                    <div className="dna-content-board-of">

                        <div className="dire-b-text">
                            <h2 style={{ fontWeight: '600' }}>
                                BOARD OF
                                DIRECTORS
                            </h2>
                            <p className='dir_para_text'>
                                SG Finserve has firm confidence in the significance of
                                corporate
                                administration rehearses that guarantee
                                responsibility,
                                straightforwardness, and adjusting the interests of
                                the multitude
                                of partners. Our chiefs are industry specialists who unite
                                figured  initiative and experience to plan wise
                                arrangements.
                            </p>
                        </div>

                        <div className="dire-b-text-2">
                            <Swiper
                                autoplay={{
                                    delay: 3000,
                                    disableOnInteraction: false
                                }}
                                loop={true}
                                style={{ position: 'relative' }}
                                modules={[Autoplay]}
                            >

                                <div>
                                    <SwiperSlide className='custom_background_dir'>
                                        <div className='custom_background_dir-inside'>
                                            <div className='dir_mission_swiper' >
                                                <img src={rohan} alt='hs upendra' className='director-image_mission' />
                                                <div className='text-dir-box'>
                                                    <h3 className='dir_heading_text'>  Mr. Rohan Gupta</h3>
                                                    <p className='dir_para_text'>Mr. Rohan Gupta is young and dynamic entrepreneur, aged 25 years has done BBA. He is having around 3 years of experience in Marketing & Branding with promising leadership qualities.</p>
                                                    <button onClick={handleOpen} className='know-btn-bord'>Know more</button>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide className='custom_background_dir'>
                                        <div className='custom_background_dir-inside'>
                                            <div className='dir_mission_swiper'>
                                                <img src={uprndra} alt='hs upendra' className='director-image_mission' />
                                                <div className='text-dir-box'>
                                                    <h3 className='dir_heading_text'>Mr. H S Upendra Kamath</h3>
                                                    <p className='dir_para_text'>Mr. H S Upendra Kamath is Ex CMD of Vijaya Bank. Ex MD &amp; CEO of Tamilnad Mercantile Bank Limited (TMB) with more than 4 decades of meritorious service in the Indian Banking Industry out of which he served Union Bank of India (UBI) for 35 years. He served in three other Public Sector Banks. </p>
                                                    <button onClick={handleOpen1} className='know-btn-bord'>Know more</button>

                                                </div>

                                            </div>
                                        </div>
                                    </SwiperSlide>


                                    <SwiperSlide className='custom_background_dir'>
                                        <div className='custom_background_dir-inside'>
                                            <div className='dir_mission_swiper'>
                                                <img src={dukhbandu} alt='hs upendra' className='director-image_mission' />
                                                <div className='text-dir-box'>
                                                    <h3 className='dir_heading_text'>Mr. Dukhabandhu Rath</h3>
                                                    <p className='dir_para_text'>Mr. Dukhabandhu Rath is a Senior Top Executive Banker with almost 4 decades of meritorious service in the Indian Banking Industry out of which he had served 36 years in SBI. He joined State Bank of India (SBI) in 1984 as a Probationary Officer (Scale I). Earlier, he served in two other Public Sector Banks.</p>
                                                    <button onClick={handleOpen2} className='know-btn-bord'>Know more</button>

                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide className='custom_background_dir'>
                                        <div className='custom_background_dir-inside'>
                                            <div className='dir_mission_swiper'>
                                                <img src={anil} alt="Anil Kumar Bansal" className='director-image_mission' />
                                                <div className='text-dir-box'>
                                                    <h3 className='dir_heading_text'>Mr. Anil Kumar Bansal</h3>
                                                    <p className='dir_para_text'>Mr. Anil Kumar Bansal has 4 decades of experience in the banking industry. He worked as General manager of Union Bank overseeing North zone operations for 36 years and then worked as an executive Director in Indian Overseas Bank. Apart from SGFL, He is also the independent Director of APL Apollo, NABARD, CARE Ratings, Canara HSBC OBC Life Insurance Company.</p>
                                                    <button onClick={handleOpen3} className='know-btn-bord'>Know more</button>

                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide className='custom_background_dir'>
                                        <div className='custom_background_dir-inside'>
                                            <div className='dir_mission_swiper'>
                                                <img src={AshaAnil} alt='asha anil aggrawal' className='director-image_mission' />
                                                <div className='text-dir-box' >
                                                    <h3 className='dir_heading_text'>Ms. Asha Anil Agarwal</h3>
                                                    <p className='dir_para_text'>Ms. Asha Anil Agarwal is a former Principal Chief Commissioner of the Income Tax in the Indian Revenue Service, Ministry of Finance, Central Government India, Ms. Agarwal, brings with her around four decades of rich experience in the Indian Revenue Service. Her impressive knowledge of Taxation, Finance and General Management are strongly backed by her rich professional experience. </p>
                                                    <button onClick={handleOpen4} className='know-btn-bord'>Know more</button>

                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>




                                    <SwiperSlide className='custom_background_dir'>
                                        <div className='custom_background_dir-inside'>
                                            <div className='dir_mission_swiper'>
                                                <img src={rahul} alt='hs upendra' className='director-image_mission' />
                                                <div className='text-dir-box' >
                                                    <h3 className='dir_heading_text'>Mr. Rahul Gupta</h3>
                                                    <h6>(Director & Promoter)</h6>
                                                    <p className='dir_para_text'>
                                                        Mr. Rahul Gupta is a highly accomplished individual with a diverse educational background and significant experience in the business world. Here's a summary of his qualifications and achievements:
                                                    </p>
                                                    <button onClick={handleOpen5} className='know-btn-bord'>Know more</button>

                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                </div>
                                <div className='btn-class-dir'>
                                    <NavButton />
                                </div>
                            </Swiper >
                        </div>

                    </div>














                </section >
            </section> */}




            <section className='bd-main'>
                <div style={{ padding: '10px', textAlign: 'center' }}>
                    <h2 style={{ height: '50px', textAlign: 'center' }} className='h2-text-new-board'>Board of Directors</h2>
                    <p className='p-text-new-para '>  SG Finserve has firm confidence in the significance of corporate administration rehearses that guarantee <br /> responsibility, straightforwardness, and adjusting the interests of the multitude of partners. Our chiefs are <br /> industry specialists who unite figured  initiative and experience to plan wise arrangements.</p>
                </div>

                <div className='bd-in-m'>
                    <Swiper
                        slidesPerView={2}
                        grid={{
                            rows: 1,
                        }}

                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: true
                        }}
                        modules={[Grid, Pagination, Autoplay]}
                        className="mySwiper"
                    >

                        <SwiperSlide className='custom_background_dir'>
                            <div className='custom_background_dir-inside'>
                                <div className='dir_mission_swiper' >
                                    <img src={rohan} alt='hs upendra' className='director-image_mission' />
                                    <div className='text-dir-box'>
                                        <h3 className='dir_heading_text'>  Mr. Rohan Gupta</h3>
                                        <p className='dir_para_text'>Mr. Rohan Gupta is young and dynamic entrepreneur, aged 25 years has done BBA. He is having around 3 years of experience in Marketing & Branding with promising leadership qualities.</p>
                                        <button onClick={handleOpen} className='know-btn-bord'>Know more</button>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide className='custom_background_dir'>
                            <div className='custom_background_dir-inside'>
                                <div className='dir_mission_swiper'>
                                    <img src={uprndra} alt='hs upendra' className='director-image_mission' />
                                    <div className='text-dir-box'>
                                        <h3 className='dir_heading_text'>Mr. H S Upendra Kamath</h3>
                                        <p className='dir_para_text'>Mr. H S Upendra Kamath is Ex CMD of Vijaya Bank. Ex MD &amp; CEO of Tamilnad Mercantile Bank Limited (TMB) with more than 4 decades of meritorious service in the Indian Banking Industry out of which he served Union Bank of India (UBI) for 35 years. He served in three other Public Sector Banks. </p>
                                        <button onClick={handleOpen1} className='know-btn-bord'>Know more</button>

                                    </div>

                                </div>
                            </div>
                        </SwiperSlide>


                        <SwiperSlide className='custom_background_dir'>
                            <div className='custom_background_dir-inside'>
                                <div className='dir_mission_swiper'>
                                    <img src={dukhbandu} alt='hs upendra' className='director-image_mission' />
                                    <div className='text-dir-box'>
                                        <h3 className='dir_heading_text'>Mr. Dukhabandhu Rath</h3>
                                        <p className='dir_para_text'>Mr. Dukhabandhu Rath is a Senior Top Executive Banker with almost 4 decades of meritorious service in the Indian Banking Industry out of which he had served 36 years in SBI. He joined State Bank of India (SBI) in 1984 as a Probationary Officer (Scale I). Earlier, he served in two other Public Sector Banks.</p>
                                        <button onClick={handleOpen2} className='know-btn-bord'>Know more</button>

                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>


                        <SwiperSlide className='custom_background_dir'>
                            <div className='custom_background_dir-inside'>
                                <div className='dir_mission_swiper'>
                                    <img src={AshaAnil} alt='asha anil aggrawal' className='director-image_mission' />
                                    <div className='text-dir-box' >
                                        <h3 className='dir_heading_text'>Ms. Asha Anil Agarwal</h3>
                                        <p className='dir_para_text'>Ms. Asha Anil Agarwal is a former Principal Chief Commissioner of the Income Tax in the Indian Revenue Service, Ministry of Finance, Central Government India, Ms. Agarwal, brings with her around four decades of rich experience in the Indian Revenue Service. Her impressive knowledge of Taxation, Finance and General Management are strongly backed by her rich professional experience. </p>
                                        <button onClick={handleOpen4} className='know-btn-bord'>Know more</button>

                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>


                        
                        <div className='btn-class-dir'>
                            <NavButton />
                        </div>

                    </Swiper>

                </div>

            </section>















            {/* Rohan kumar gupta*/}

            {
                visible && (
                    <div>
                        <div className="pop-overlay"></div> {/* Modal overlay */}
                        <div className="pop-box-team">
                            <IoMdClose onClick={handleOpen} className="close-icon" />


                            <div style={{ textAlign: 'left' }} ref={boxRef}>
                                <h6 className='p-text-team'>Mr. Rohan Gupta</h6>
                                <p className='p-sub-heading' style={{ margin: '0', fontWeight: '600', fontSize: '18px' }}>Director & Promoter</p>
                                <p className="p-text-team-para " >Mr. Rohan Gupta epitomizes the essence of a young and dynamic entrepreneur, combining youthfulness with a strong commitment to excellence. Here's a summary of his profile:</p>
                                <div>

                                    <p className="p-text-team-para "><b> Qualifications and Expertise:</b>
                                        <ul>
                                            <li>Holds a Bachelor's degree in Business Administration (BBA), indicating a solid academic foundation in business management principles.</li>
                                            <li>Demonstrates an unwavering commitment to excellence, reflecting a dedication to achieving high standards of performance and outcomes.</li>
                                        </ul>
                                    </p>

                                    <p className="p-text-team-para "><b> Professional Experience:</b>
                                        <ul>
                                            <li>Serves as a dynamic and accomplished young director within the company, showcasing leadership capabilities at a young age.</li>
                                            <li>Possesses four years of hands-on experience in Marketing and Branding, indicating proficiency in strategic marketing initiatives and brand development.</li>

                                        </ul>
                                    </p>

                                    <p className="p-text-team-para "><b> Personal Attributes:</b>
                                        <ul>
                                            <li>Known for his engaging personality and strong interpersonal skills, Mr. Gupta fosters collaboration and open communication within the organization.</li>
                                            <li>Thrives in team environments, consistently inspiring and bringing out the best in his colleagues through effective leadership and encouragement.</li>

                                        </ul>
                                    </p>

                                    <p className="p-text-team-para ">
                                        Mr. Rohan Gupta's combination of youthfulness, academic qualifications, professional expertise, and exceptional personal attributes position him as a valuable asset to the organization. His leadership qualities and ability to foster collaboration make him a promising entrepreneur with the potential to drive growth and success in the business arena.


                                    </p>


                                </div>

                            </div>

                        </div>
                    </div>
                )
            }



            {/* Mr. H S Upendra Kamath */}

            {
                visible1 && (
                    <div style={{ textAlign: 'left' }}>
                        <div className="pop-overlay"></div> {/* Modal overlay */}
                        <div className="pop-box-team">
                            <IoMdClose onClick={handleOpen1} className="close-icon" />
                            <div ref={boxRef}>
                                <h6 className='p-text-team'>Mr. HSU Kamath </h6>
                                <p className='p-sub-heading' style={{ margin: '0', fontWeight: '600', fontSize: '18px' }}>Non-Executive Independent Director</p>
                                <p className="p-text-team-para " >H S Upendra Kamath is Ex CMD of Vijaya Bank &amp; Ex MD &amp; CEO of Tamilnad Mercantile Bank Limited (TMB) with more than 4 decades of meritorious service in the Indian Banking Industry out of which he served Union Bank of India (UBI) for 35 years. He joined UBI in 1973 as a Probationary Officer (General Manager) worked as Branch Manager for 12 years. Thereafter, he served in three other Public Sector Banks. As a business leader and results-driven professional a wide variety of roles and assignments have been handled by him successfully at the Branch Office, Independent Circles,Regional Offices, Head Office and other operational assignments of UBI.</p>
                                <p className="p-text-team-para " >He has won several honors, awards and recognitions, such as “Best Customer Friendly Banker Award 2012”, SIDBI’s CGFTMSE award, “National award for excellence in lending to Micro Enterprises”, “IT Leadership Award 2013” by Amity University etc. He has a proven track record of consistently exceeding business, financial and operational targets whilst ensuring compliance with highest service standards and operational efficiencies. The various roles encompassed identifying, developing and implementing innovative banking practices to achieve growth combined with risk mitigation. He is a Certified Associate of Indian Institute of Bankers (CAIIB) and also holds B. Com degree.</p>
                                <p className="p-text-team-para " >Mr. Kamath has requisite skills and capabilities in the areas of MSME, Retail Banking, Priority Sector, Recovery and Legal, Risk Management Department, International Banking Division and International Operations, Treasury, Credit-Monitoring and Administration, which are the key elements of managing the Company, as recognized/ identified by the Board. </p>
                            </div>

                        </div>
                    </div>
                )
            }




            {/* Mr. Dukhabandhu Rath */}

            {
                visible2 && (
                    <div style={{ textAlign: 'left' }}>
                        <div className="pop-overlay"></div> {/* Modal overlay */}
                        <div className="pop-box-team">
                            <IoMdClose onClick={handleOpen2} className="close-icon" />
                            <div ref={boxRef}>
                                <h6 className='p-text-team'>Mr. Dukhabandhu Rath  </h6>
                                <p className='p-sub-heading' style={{ margin: '0', fontWeight: '600', fontSize: '18px' }}>Non-Executive Independent Director</p>

                                <p className="p-text-team-para "> Dukhabandhu Rath is a Senior Top Executive Banker with almost 4 decades of service in the Indian Banking Industry out of which he served State Bank of India (SBI) for 36 years. He had joined SBI in 1984 as a Probationary Officer (Scale 1). Earlier, he had served in two other Public Sector Banks. His profile indicates that he is a business leader and result-driven professional and has managed wide variety of roles and assignments successfully at the Corporate Office, Independent Circles, Regional Offices and other operational assignments of SBI in India and abroad. </p>


                            </div>

                        </div>
                    </div>
                )
            }


            {/* Mr. Anil Kumar Bansal*/}


            {
                visible3 && (
                    <div style={{ textAlign: 'left' }}>
                        <div className="pop-overlay"></div> {/* Modal overlay */}
                        <div className="pop-box-team">
                            <IoMdClose onClick={handleOpen3} className="close-icon" />
                            <div ref={boxRef}>
                                <h6 className='p-text-team'>Mr. Anil Kumar Bansal </h6>
                                <p className='p-sub-heading' style={{ margin: '0', fontWeight: '600', fontSize: '18px' }}>Non-Executive Independent Director</p>

                                <p className="p-text-team-para "> Anil Kumar Bansal is a former executive director of the Indian Overseas Bank, Mr. Bansal, brings with him four decades of rich experience in the banking industry. His impressive knowledge of banking, the Indian economy, corporate affairs and risk and ratings are strongly backed by his rich professional experience. Currently, he is serving as the Director of APL Apollo Tubes Limited, Rockland Finstock Limited, IFCI Venture Capital Funds Limited and GVFL Trustee Co Pvt. Ltd. He is also the former director of Canara HSBC Oriental Bank of Commerce Life Insurance Company Limited, CARE Ratings Limited and NABARD. </p>

                            </div>

                        </div>
                    </div>
                )
            }


            {/*Ms. Asha Anil Agarwal*/}

            {
                visible4 && (
                    <div style={{ textAlign: 'left' }}>
                        <div className="pop-overlay"></div> {/* Modal overlay */}
                        <div className="pop-box-team">
                            <IoMdClose onClick={handleOpen4} className="close-icon" />
                            <div ref={boxRef}>
                                <h6 className='p-text-team'>Ms. Asha Anil Agarwal  </h6>
                                <p className='p-sub-heading' style={{ margin: '0', fontWeight: '600', fontSize: '18px' }}>Non-Executive Independent Director</p>

                                <p className="p-text-team-para ">Asha Anil Agarwal is a former Principal Chief Commissioner of the Income Tax in the Indian Revenue Service, Ministry of Finance, Central Government India, Ms. Agarwal, brings with her around four decades of rich experience in the Indian Revenue Service. Her impressive knowledge of Taxation, Finance and General Management are strongly backed by her rich professional experience. Currently, she is not serving directorship in any other Company.” </p>


                            </div>

                        </div>
                    </div>
                )
            }

            {/* Mr. Rahul Gupta */}

            {
                visible5 && (
                    <div style={{ textAlign: 'left' }}>
                        <div className="pop-overlay"></div> {/* Modal overlay */}
                        <div className="pop-box-team">
                            <IoMdClose onClick={handleOpen5} className="close-icon" />
                            <div ref={boxRef}>
                                <h6 className='p-text-team'>Mr. Rahul Gupta   </h6>
                                <p className='p-sub-heading' style={{ margin: '0', fontWeight: '600', fontSize: '18px' }}>Director & Promoter</p>

                                <p className="p-text-team-para ">Mr. Rahul Gupta is a highly accomplished individual with a diverse educational background and significant experience in the business world. Here's a summary of his qualifications and achievements: </p>

                                <p className="p-text-team-para "><b>Education:</b>
                                    <ul>
                                        <li>Completed B.Com. (Hons.) from Delhi University, showcasing a strong foundation in commerce and business principles.</li>
                                        <li>Undertook executive courses in Management from prestigious institutions such as the London School of Economics and the Indian Institute of Management, Ahmedabad (IIMA), demonstrating a commitment to continuous learning and professional development.</li>
                                    </ul>
                                </p>

                                <p className="p-text-team-para "><b>Entrepreneurial Experience:</b>
                                    <ul>
                                        <li>Possesses approximately 8 years of experience in the steel tubes manufacturing business, indicating a deep understanding of the industry and its dynamics.</li>
                                        <li>Successfully managed various aspects of the business, including operational management, marketing, and institutional sales, highlighting his multifaceted skills and expertise.</li>
                                    </ul>
                                </p>

                                <p className="p-text-team-para "><b>Accolades:</b>
                                    <ul>
                                        <li>Recognized as a promising entrepreneur and organizational leader, receiving the 'Young Achiever Award' for Organization Building at the Global HR Summit 2017. This accolade underscores his ability to drive growth and success within his organization.</li>
                                    </ul>
                                </p>

                                <p className="p-text-team-para ">Mr. Rahul Gupta's educational qualifications, executive courses, and hands-on experience in business operations and management position him as a dynamic and capable leader in the steel tubes manufacturing industry. His entrepreneurial spirit, coupled with his achievements and recognition, reflects his potential to make significant contributions to the business landscape.</p>

                            </div>

                        </div>
                    </div>
                )
            }




        </>
    );
}

export default Director;
