import React, { useState, useEffect } from 'react';
import '../hamcss/hamburger.css';
import { AiOutlineClose } from 'react-icons/ai';
import { MdMenuOpen } from 'react-icons/md';
import { Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';

const Hamburger = () => {
    const [toggle, setToggle] = useState(false);

    // Close the menu when scrolling
    useEffect(() => {
        const handleScroll = () => {
            if (toggle) {
                setToggle(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [toggle]);




    const [visible, setIsVisible] = useState(false)

    const handleClick = () => {
        setIsVisible(!visible)
    }



    return (
        <>
            <div style={{ zIndex: 50, marginLeft: 250, }} className="container_menu">
                {toggle ? (
                    <AiOutlineClose
                        onClick={() => setToggle(!toggle)}
                        className="menu-icon"
                    />
                ) : (
                    <MdMenuOpen
                        onClick={() => setToggle(!toggle)}
                        className="menu-icon"
                    />
                )}

                <div className={`responsive-menu ${toggle ? 'visible' : ''} md:hidden p-2 gap-7 text-white fixed`} >
                    <div className="menu-list-item">
                        <Link to={'/'}>Home</Link>
                    </div>
                    <div className="menu-list-item">
                        <Link to={'/aboutUs'} >
                            About us
                        </Link>
                    </div>
                    <div >
                        <div className="menu-list-item">
                            <Link onClick={handleClick} to={'#'}> Solution <i className="fa fa-angle-down slide-know-btn"></i></Link>
                        </div>
                        {visible && (
                            <>
                                <div className="menu-list-item">
                                    <Link to={'/dealer'}><div > Dealer financing </div></Link>
                                </div>
                                <div className="menu-list-item">
                                    <Link to={'/retailer'}><div  > Retailer financing </div></Link>
                                </div>
                                <div className="menu-list-item">
                                    <Link to={'/vender'}><div > Vendor Financing</div> </Link>
                                </div>
                                <div className="menu-list-item">
                                    <Link to={'/transport_logistics'}><div> Logistics/ Transporter<br /> financing </div> </Link>
                                </div>
                            </>
                        )
                        }
                    </div >

                    {/* <Link to={'/career'} className="menu-list-item">CAREERS</Link> */}
                    <div className="menu-list-item">
                        <Link to={'/invester'} >Investor relations</Link>
                    </div>

                    <div className="menu-list-item">
                        <Link to={'/csr-initiative'} > CSR Initiative</Link>
                    </div>

                    <div className="menu-list-item">
                        <Link to={'#'} > Blog</Link>
                    </div>

                    <div className="menu-list-item">
                        <Link to={'/contact'} >Contact us</Link>
                    </div>



                    <div style={{ fontWeight: '600', color: 'black' }} className="info-contact">
                        <i className="fa fa-phone"></i>
                        <span >
                            <a href="tel:+91-9205556122">+91-9205556122</a>
                        </span>

                        <div className="info-contact">
                            <i className="fa fa-envelope"></i>
                            <span >info@sgfinserve.com</span><br />
                        </div>

                    </div>



                </div >




            </div >
        </>
    );
};

export default Hamburger;
