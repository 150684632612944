import React from 'react'
import DotRing from '../../component/dot/DotRing';
import Navbar from '../Navbar';
import Footer from '../Footer';
import '../../hamcss/blog.css';
import careerBanner from '../../assests/career/career.webp';
import l from '../../assests/le.webp';
import Recents from './Recents';



const B1 = () => {
    return (
        <>


            <section style={{ display: 'flex', justifyContent: 'center' }}>
                <section className="containner">

                    <DotRing />

                    <Navbar />
                    <div>
                        <div className="BreadArea" style={{ backgroundImage: `url(${careerBanner})`, height: '300px' }}>
                            <div
                                className="container">
                                {/* <h2>Blogs</h2> */}
                            </div>
                        </div>
                    </div>




                    <section className='main-container-blogs'>

                        <div className="wraper-blogs">

                            <div className="main-blog-con">


                                <div style={{ marginTop: '60px' }} >
                                    <p style={{ margin: '0' }} className='p-read'> By <span style={{ fontWeight: '500' }}>Abhishek Mahajan</span>   ,   <span> APRIL 04,2024 </span> </p>
                                </div>
                                <div  >
                                    <h2>How Invoice Financing Can Increase Your Business Cash Flow</h2>
                                </div>


                                <div style={{ width: '100%', }}>
                                    <img src={l} alt="leaf" />
                                </div>

                                <p style={{ marginTop: '20px' }} className='para-text-b'> Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis, adipisci eligendi? Laborum corrupti est non totam dolorem praesentium, facilis odit quaerat, quae sapiente debitis laudantium ullam nesciunt obcaecati, perferendis nam? Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod qui temporibus officiis : </p>
                                <div  >
                                    <h2>How Invoice Financing Can Increase Your Business Cash Flow</h2>
                                    <p className='para-text-b'> Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis, adipisci eligendi? Laborum corrupti est non totam dolorem praesentium, facilis odit quaerat, quae sapiente debitis laudantium ullam nesciunt obcaecati, perferendis nam? Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod qui temporibus officiis : </p>

                                </div>
                            </ div>


                            <Recents />


                        </div>

                    </section>





                    <Footer />

                </section>
            </section>


        </>
    )
}

export default B1