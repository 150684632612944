import React from "react";
import { useEffect } from "react";
import AOS from "aos";
import Navbar from "./Navbar";
import Footer from "./Footer";
import contactBanner from "../assests/contact/contact.webp";
import Careers from '../component/Careers';

import { FloatingWhatsApp } from 'react-floating-whatsapp';
import DotRing from '../component/dot/DotRing';
import '../hamcss/contact.css';
import ContactForm from "./ContactForm";
import bg from '../assests/contact/contactBG.webp';
import '../hamcss/contact.css';

const Contact = () => {
  useEffect(() => {
    AOS.init();
  });

  return (

    <>


      <div>
        <Navbar />
        <DotRing />

        {/* <!--start banner slider--> */}
        <section>
          <div
            className="BreadArea"
            style={{
              backgroundImage: `url(${contactBanner})`,
              // backgroundImage: "url('images/Bread.png')",
            }}
          >
            <div className="container">

              <h2 className="shadows">
                <span>C</span>
                <span>O</span>
                <span>N</span>
                <span>T</span>
                <span>A</span>
                <span>C</span>
                <span>T</span>
                <span>U</span>
                <span>S</span>
              </h2>

            </div>
          </div>
        </section>
      </div>

      <section style={{ display: 'flex', justifyContent: 'center', }}>
        <section className="containner">

          <section className='main-wraper-cl' >
            <div className="main-con-cl">
            <ContactForm />

              <div className="col-sm-4">
              <h3 className="headings">Contact Information</h3>
              <div class="single-footer-widget" >
              <div className="info-contact">
                                    <i className="fa fa-phone"></i>
                                    <span>
                                        <a href="tel:+91 120-4041400">+91-120-4041400</a>
                                    </span>
                                    <br />
                                    <span>
                                        <a href="tel:+91-11-22373437">+91-11-22373437</a>
                                    </span>

                                </div>
                                <div className="info-contact">
                                    <i className="fa fa-envelope"></i>
                                    <span>info@sgfinserve.com</span>
                                </div>
                                <div className="info-contact">
                                    <i className="fa fa-map-marker"></i>
                                    <span>36 (2nd floor), Kaushambi Near Anand Vihar Terminal Ghaziabad Uttar Pradesh 201010
                                        (India).</span><br />
                                    <span>37, Hargobind Enclave Vikas Marg New Delhi 110092 (India).</span>
                                    
                                </div>
                                </div>
               {/* <div className="two-b">
                  <div className="con-card-cl">
                    <img data-aos="fade-up" data-aos-duration="1000" src="/images/phone.png" alt='contact' />
                    <h5 > Phone Number</h5>
                    <p style={{ fontSize: "18px" }} >+91-120-4041400 <br /> +91-11-22373437</p>
                  </div>



                  <div className="con-card-cl">
                    <img src="/images/mail.png" alt='contact' />
                    <h5 data-aos="fade-up" data-aos-duration="1000">
                      Email Id
                    </h5>
                    <p style={{ fontSize: "18px" }}>
                      info@sgfinserve.com
                    </p>
                  </div>

                </div>

                <div className="two-b">
                  <div className="con-card-cl" >
                    <img src="/images/map-marker.png" alt='contact' />
                    <h5 data-aos="fade-up" data-aos-duration="1000">   Our Address</h5>
                    <h6 style={{ fontSize: "18px" }}>Corporate office</h6>
                    <p style={{ fontSize: "18px" }}>
                      35, 36 Kaushambi, Near Anand Vihar Terminal,
                      Ghaziabad, Uttar Pradesh 201010
                    </p>
                  </div>



                  <div className="con-card-cl" >
                    <img src="/images/map-marker.png"
                      alt='contact'
                    />
                    <h5 >  Our Address </h5>
                    <h6 style={{ fontSize: "18px" }} >  Registered office </h6>
                    <p style={{ fontSize: "18px" }} >
                      37, Hargobind Enclave Vikas Marg New Delhi 110092 (India)
                    </p>
                  </div>

                </div>
                */}
              </div>

              

            </div>




          </section>








          {/* <div>
          <ReactWhatsapp number="+919791028585" message="Hello World!!!" />
          <FloatingWhatsApp phoneNumber="919791028585" accountName="srini" />
        </div> */}

          <Footer />


        </section>
      </section >
    </>
  );
};

export default Contact;
