import '../../hamcss/solutiontext.css';
import invoice from '../../assests/solution/invoice.png';
import sup from '../../assests/solution/Supply.webp';
import invent from '../../assests/solution/invent.webp';
import dyna from '../../assests/solution/dy.png';
import tech from '../../assests/solution/tech.png';
import risk from '../../assests/solution/risk.png';
import cun from '../../assests/solution/cuns.png';
import costu from '../../assests/solution/cs.png';
import supp from '../../assests/solution/sup.png';

import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import { EffectCoverflow, Pagination, Autoplay } from 'swiper/modules';




const SolutionText = () => {
    return (
        <>

            {/* <section className="main-solution-text-box">

                <div>
                    <h2 style={{ fontWeight: '700', textAlign: 'center' }}>
                        SOLUTIONS
                    </h2>


                    <div>
                        <p className='p-text-solu-text'>SG Finserve Limited is a one-stop shop for all kinds of Supply Chain Finance Solutions offering various financial products and services tailored to meet the diverse needs of businesses involved in the Supply Chain.  </p>
                        <h4 style={{ fontWeight: '500' }}>Some of the key features and offerings of SG Finserve Limited include the following:</h4>
                        <ul>
                            <li className='p-text-solu-text'><b>Invoice Financing: </b> Providing financing options based on outstanding invoices to help businesses access working capital tied up in unpaid invoices.</li>
                            <li className='p-text-solu-text'><b> Supply Chain Financing:</b>  Offering tailored financing solutions to optimize cash flow and strengthen relationships between buyers and suppliers along the supply chain.</li>
                            <li className='p-text-solu-text'><b> Inventory Financing:</b> Providing financing options to help businesses manage inventory levels effectively without tying up excessive capital.</li>
                            <li className='p-text-solu-text'><b>Dynamic Discounting: </b> Allowing suppliers the option to receive early payment in exchange for discounts, improving cash flow for both buyers and suppliers.</li>
                            <li className='p-text-solu-text'><b>Technology Integration: </b>Leveraging technology platforms to streamline processes, enable real-time tracking, and provide insights into supply chain financial performance. </li>
                            <li className='p-text-solu-text'><b>Risk Management: </b> Offering solutions to mitigate risks associated with supply chain disruptions, currency fluctuations, credit risks, and compliance.</li>
                            <li className='p-text-solu-text'><b> Consulting Services:</b> Providing advisory services to optimize supply chain finance strategies, improve working capital management, and enhance overall efficiency.</li>
                            <li className='p-text-solu-text'><b>Customization:  </b> Tailoring solutions to meet the specific needs and requirements of individual businesses, considering factors such as industry, size, and geographic location. </li>
                            <li className='p-text-solu-text'><b>Customer Support: </b> Providing dedicated customer support to address inquiries, resolve issues, and offer guidance on utilizing supply chain finance solutions effectively.</li>

                            <p className='p-text-solu-text'> By offering these comprehensive services, SG Finserve Limited aims to simplify and optimize supply chain finance processes for businesses, ultimately contributing to improved liquidity, reduced risk, and enhanced operational efficiency. </p>

                        </ul>


                    </div>



                </div>




            </section > */}




            {/* <section className="main-solution-text-box">

                <h2 style={{ fontWeight: '700', textAlign: 'center', letterSpacing: '0.2px' }}>
                    Explore Our Solutions
                </h2>


                <p className='p-text-solu-text'>SG Finserve Limited is a one-stop shop for all kinds of Supply Chain Finance  Solutions offering various  financial <br /> products and  services tailored to meet the diverse needs of businesses involved in the Supply Chain.  </p>
                <h4 style={{ fontWeight: '500', textAlign: 'center' }}>Some of the key features and offerings of SG Finserve Limited include the following:</h4> 


                <div className='card-clas-main-box'>

                    <Swiper
                        effect={'coverflow'}
                        grabCursor={true}
                        centeredSlides={true}
                        // slidesPerView={3}
                        coverflowEffect={{
                            rotate: 50,
                            stretch: 0,
                            depth: 100,
                            modifier: 1,
                            slideShadows: true,
                        }}
                        pagination={true}
                        modules={[EffectCoverflow, Pagination, Autoplay]}
                        className="mySwiper"
                        breakpoints={{
                            // When window width is <= 640px
                            640: {
                                slidesPerView: 1,
                                spaceBetween: 20
                            },
                            // When window width is <= 768px
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 30
                            },
                            // When window width is <= 1024px
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 40

                            },
                        }}

                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: false,
                            pauseOnMouseEnter: true
                        }}

                    >
                        <SwiperSlide>
                            <div className='card-clas-main'>
                                <img src={invoice} alt='invoice' className="image-con" />
                                <b className='p-text-solu-text'>Invoice Financing </b>
                                <p className='p-text-solu-text-para'> Providing financing options based on outstanding invoices to help businesses access working capital tied up in unpaid invoices.</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='card-clas-main'>
                                <img src={sup} alt='invoice' className="image-con" />
                                <b className='p-text-solu-text'>Supply Chain Financing </b>
                                <p className='p-text-solu-text-para'>Offering tailored financing solutions to optimize cash flow and strengthen relationships between buyers and suppliers along the supply chain.</p>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className='card-clas-main'>
                                <img src={invent} alt='invoice' className="image-con" />
                                <b className='p-text-solu-text'>Inventory Financing </b>
                                <p className='p-text-solu-text-para'>Providing financing options to help businesses manage inventory levels effectively without tying up excessive capital.</p>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className='card-clas-main'>
                                <img src={dyna} alt='invoice' className="image-con" />
                                <b className='p-text-solu-text'>Dynamic Discounting </b>
                                <p className='p-text-solu-text-para'>Allowing suppliers the option to receive early payment in exchange for discounts, improving cash flow for both buyers and suppliers. </p>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className='card-clas-main'>
                                <img src={tech} alt='invoice' className="image-con" />
                                <b className='p-text-solu-text'>Technology Integration </b>
                                <p className='p-text-solu-text-para'> Leveraging technology platforms to streamline processes, enable real-time tracking, and provide insights into supply chain financial performance</p>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className='card-clas-main'>
                                <img src={risk} alt='invoice' className="image-con" />
                                <b className='p-text-solu-text'> Risk Management</b>
                                <p className='p-text-solu-text-para'>Offering solutions to mitigate risks associated with supply chain disruptions, currency fluctuations, credit risks, and compliance. </p>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className='card-clas-main'>
                                <img src={cun} alt='invoice' className="image-con" />
                                <b className='p-text-solu-text'>Consulting Services </b>
                                <p className='p-text-solu-text-para'>Providing advisory services to optimize supply chain finance strategies, improve working capital management, and enhance overall efficiency. </p>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className='card-clas-main'>
                                <img src={costu} alt='invoice' className="image-con" />
                                <b className='p-text-solu-text'>Customization </b>
                                <p className='p-text-solu-text-para'>Tailoring solutions to meet the specific needs and requirements of individual businesses, considering factors such as industry, size, and geographic location. </p>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div className='card-clas-main'>
                                <img src={supp} alt='invoice' className="image-con" />
                                <b className='p-text-solu-text'> Customer Support</b>
                                <p className='p-text-solu-text-para'>Providing dedicated customer support to address inquiries, resolve issues, and offer guidance on utilizing supply chain finance solutions effectively. </p>
                            </div>
                        </SwiperSlide>

                    </Swiper>

                    <p className='p-text-solu-text'> By offering these comprehensive services, SG Finserve Limited aims to simplify and optimize supply chain finance processes for businesses, ultimately contributing to improved liquidity, reduced risk, and enhanced operational efficiency. </p> 


                </div>





            </section >*/}
            



        </>
    )
}

export default SolutionText